import React, { useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import { HiDocumentText } from "react-icons/hi2";
import { MdOutlineArrowBack } from "react-icons/md";
import { UploadDocument } from "../components/Simplify/UploadDocument";
import { BsStars } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Oval, Puff } from "react-loader-spinner";
import {
  convertTextToSlug,
  getSimplifiedCardsData,
  isValidFileType,
} from "../functions";
import { BiCheck, BiChevronDown, BiSave } from "react-icons/bi";
import { FaBoxArchive } from "react-icons/fa6";
import { getDocumentDraftAPI, getMyDraftsAPI } from "../api/apiCalls";
import { IoClose } from "react-icons/io5";
import { Link, redirect, useNavigate } from "react-router-dom";

export const Simplify = () => {
  const [fileDropped, setFileDropped] = useState(null);

  const openFileUpload = () => {
    const input = document.getElementById("fileInput");
    input.click();
  };

  const { token } = useSelector((state) => state.user);

  const [documentUploading, setDocumentUploading] = useState(false);
  const [streamStatus, setStreamStatus] = useState("");
  const [elements, setElements] = useState([]);

  const navigate = useNavigate();

  let newElement = null;

  let localInfo = [];

  const addToElements = (object) => {
    //"local info: ", localInfo);
    localInfo.push(object);
    setElements((prev) => {
      return [...localInfo];
    });
  };

  const readStream = async (stream) => {
    const reader = stream.getReader();
    let alldata = "";

    while (true) {
      // newElement = "";
      setStreamStatus("PENDING");
      const { done, value } = await reader.read();
      if (done) {
        // setChats([...newChatsArray]);
        //

        // // console.log("NE", newElement);
        // addToElements({
        //   type: "PARAGRAPH",
        //   text: newElement,
        // });
        addToElements({
          type: "PARAGRAPH",
          text: newElement.replace("\n", ""),
        });

        // console.log(alldata);
        saveSimplifiedDocument();

        return;
      }

      const populatStreams = (object) => {
        console.log(object.data);
        alldata = alldata + object.data;
        if (newElement === null && object.data.includes("!")) {
          newElement = "";
          return;
        }

        if (newElement !== null && object.data.includes("!")) {
          // console.log("Completed Title", newElement);
          addToElements({ type: "TITLE", text: newElement });
          newElement = null;
          return;
        }

        if (newElement === null && object.data.includes("#")) {
          newElement = "";
          return;
        }

        if (newElement !== null && object.data.includes("#")) {
          // console.log("Will try - ", newElement);
          if (newElement.includes(".")) {
            let formatedText = "";

            // Get the first 5 characters of newElement
            let first5 = newElement.slice(0, 5);

            // Check for "\n" in the first 5 characters
            if (first5.includes("\n")) {
              // Remove "\n" from the first 5 characters
              first5 = first5.replace(/\n/g, "");
            }

            // Extract the remaining part of newElement
            let remainingPart = newElement.slice(5);

            // Concatenate the formatted parts together
            formatedText = first5 + remainingPart;

            // console.log(formatedText);

            // console.log(formatedText);

            // if (
            //   newElement.substring(0, 3) === "\n\n\n" ||
            //   newElement.substring(0, 4) === " \n\n\n"
            // )
            //   formatedText = newElement.substring(3);
            // else if (
            //   newElement.substring(0, 2) === "\n\n" ||
            //   newElement.substring(0, 3) === " \n\n"
            // )
            //   formatedText = newElement.substring(2);
            // else if (
            //   newElement.substring(0, 1) === "\n" ||
            //   newElement.substring(0, 2) === " \n"
            // )
            //   formatedText = newElement.substring(1);
            // else formatedText = newElement;

            addToElements({
              type: "PARAGRAPH",
              text: formatedText + "\n\n",
            });
            newElement = "";
            return;
          }

          addToElements({
            type: "SUBTITLE",
            text: newElement,
          });

          newElement = null;
          return;
          // console.log("Completed Subtitle: ", newElement);
          addToElements({
            type: "SUBTITLE",
            text: newElement,
          });

          newElement = null;
          return;
        }

        // if (newElement && object.data.includes(".")) {
        //   newElement = newElement + object.data;
        //   addToElements({
        //     type: "PARAGRAPH",
        //     text: newElement,
        //   });

        //   // console.log("Para done: ", newElement);
        //   newElement = null;
        //   return;
        // }

        if (newElement !== null) {
          newElement = newElement + object.data;
          return;
        }

        if (newElement === null) {
          newElement = object.data;
          return;
        }

        if (document) {
          // const element = document.getElementsByClassName("chatSectionChats");
          // element[0].scrollTo(0, element[0].scrollHeight);
        }
        // setStreamMessage((prev) => {
        //   return prev + object.data;
        // });
      };

      const textDecoder = new TextDecoder();
      try {
        const object = JSON.parse(textDecoder.decode(new Uint8Array(value)));

        if (object.data) {
          console.log("inside try -> ", object.data);
          populatStreams(object);
        }
      } catch {
        const input = `${textDecoder.decode(new Uint8Array(value))}`;

        input
          .trim()
          .split("\n")
          .map((str) => {
            const object = JSON.parse(str);

            console.log("inside catch -> ", object.data);

            if (object.data) {
              populatStreams(object);
            }
          });
      }
    }
  };

  const uploadFile = async (type = "document", content, documentInfo) => {
    const formData = new FormData();

    if (type === "document") {
      formData.append("document", content);
    } else {
      formData.append("id", content);
    }

    setDocumentUploading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/document/simplify-document`,
      {
        method: "POST",
        body: formData,
        "Content-Type": "multipart/form-data",
        headers: {
          api_key: "krishna",
          authorization: token,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (response.ok) {
      setDocumentUploading(false);
      setFileDropped(
        type === "document"
          ? content
          : { name: documentInfo?.name, id: documentInfo?._id }
      );
      const stream = response.body;
      await readStream(stream);
    } else {
      setStreamStatus("NOT_STARTED");
      console.error(`Failed to fetch stream: ${response.statusText}`);
    }
  };

  // const [savingDocument, setSavingDocument] = useState(null);

  // useEffect(() => {
  //   saveSimplifiedDocument();
  // }, []);

  const saveSimplifiedDocument = async () => {
    // setSavingDocument(true);

    //elements);

    //"saving document");

    const titleElement = localInfo.filter(
      (eachElement) =>
        eachElement.type === "TITLE" && eachElement.text.length !== 0
    )[0];
    const title = titleElement ? titleElement.text : "Untitled";

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/document/save-simplified-document`,
      {
        method: "POST",
        body: JSON.stringify({
          title: title,
          content: localInfo,
        }),
        headers: {
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    );

    //"saving", response.data);

    if (response.data) {
      navigate(`/simplify/${response.data._id}`);

      // setSavingDocument(false);
      setStreamStatus("COMPLETED");

      const rightContainer = document.querySelector(".rightContainer");
      rightContainer.scroll({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setStreamStatus("COMPLETED");

      const rightContainer = document.querySelector(".rightContainer");
      rightContainer.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleFile = async (file) => {
    if (isValidFileType(file)) {
      if (file) {
        uploadFile("document", file);

        // const reader = new FileReader();

        // reader.onload = (event) => {
        //   const buffer = event.target.result;
        //   //buffer);

        //   // Now you have the file content as a buffer
        //   // You can use the 'buffer' variable for further processing
        // };

        // reader.readAsArrayBuffer(file);
      }
    } else {
      //"Invalid file type.");
    }
  };

  useEffect(() => {
    if (!fileDropped) {
      getAllSimplifiedDocuments();

      const dropArea = document.getElementById(
        "simplifyDocumentsUploadContainer"
      );
      const fileInput = document.getElementById("fileInput");

      // Prevent default behavior to enable dropping
      dropArea.addEventListener("dragover", (e) => {
        e.preventDefault();
        dropArea.classList.add("activeUploadContainer");
      });

      dropArea.addEventListener("dragleave", () => {
        dropArea.classList.remove("activeUploadContainer");
      });

      // Handle file drop
      dropArea.addEventListener("drop", (e) => {
        e.preventDefault();
        dropArea.classList.remove("activeUploadContainer");

        const files = e.dataTransfer.files;

        if (isValidFileType(files[0]) && files.length === 1) {
          handleFile(files[0]);
        }
      });

      fileInput.addEventListener("change", (e) => {
        const files = e.target.files;

        if (isValidFileType(files[0]) && files.length === 1) {
          handleFile(files[0]);
        }
      });
    }
  }, [fileDropped]);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const loaderElement = document.getElementById("simplifyDocumentLoader");
    if (loaderElement) {
      loaderElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [elements]);

  const [showSimplifiedDocuments, setShowSimplifiedDocuments] = useState(true);

  //

  const [allDocuments, setAllDocuments] = useState([]);
  const [documentsLoading, setDocumentsLoading] = useState(false);

  const getAllDocumentsAPI = async () => {
    setDocumentsLoading(true);
    const response = await getMyDraftsAPI();

    if (response.data) {
      setDocumentsLoading(false);

      setAllDocuments(response.data);
    }
  };

  const getDraft = async (id) => {
    setDocumentUploading(true);

    const response = await getDocumentDraftAPI(id);

    if (response.data) {
      // const htmlBody = atob(
      //   decodeURIComponent(
      //     response.data.document_drafts[
      //       response.data.document_drafts.length - 1
      //     ]
      //   )
      // );

      // // Create a temporary container element
      // var tempContainer = document.createElement("div");
      // tempContainer.innerHTML = htmlBody;
      // tempContainer.style.display = "none";
      // var documentText = tempContainer.textContent;
      // tempContainer.remove();

      uploadFile("id", id, response.data);
    }
  };

  const [simplifiedDocuments, setSimplifiedDocuments] = useState(null);

  const getAllSimplifiedDocuments = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/document/get-all-simplified-document`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          api_key: "krishna",
          authorization: token,
        },
      }
    ).then(async (response) => {
      const savedDocuments = await response.json();

      setSimplifiedDocuments(savedDocuments);
    });
  };

  useEffect(() => {
    getAllDocumentsAPI();
    getAllSimplifiedDocuments();
  }, []);

  return (
    <div className="simplifyContainer">
      <Navbar />

      <div className="rightContainer">
        <div className="rightContainerHeader">
          <h1 className="rightContainerHeaderText">Simplify your Document</h1>
          <p className="rightContainerHeaderDescription">
            View your document in a easy-to understand and a visual format and
            identify important points.
          </p>
        </div>

        <div className="simplifyDocumentsContainer">
          {!fileDropped && (
            <div className="simplifiedDocumentsListContainer">
              <div
                className="simplifiedDocumentsListHeader"
                onClick={() =>
                  setShowSimplifiedDocuments(!showSimplifiedDocuments)
                }
              >
                {/* <FaBoxArchive className="simplifiedDocumentsListHeaderMainIcon" /> */}
                <p className="simplifiedDocumentsListHeaderText">
                  Previously Simplified Documents{" "}
                  {simplifiedDocuments?.length > 0 &&
                    `(${simplifiedDocuments?.length})`}
                </p>
                <BiChevronDown
                  className={`simplifiedDocumentsListHeaderIcon ${
                    showSimplifiedDocuments &&
                    "simplifiedDocumentsListHeaderIconOpen"
                  }`}
                />
              </div>

              {showSimplifiedDocuments && simplifiedDocuments && (
                <div className="simplifiedDocumentsList">
                  {simplifiedDocuments && simplifiedDocuments?.length === 0 ? (
                    <p className="emptyListText">
                      No documents simplified yet.
                    </p>
                  ) : (
                    <>
                      {simplifiedDocuments.map((eachSimplifiedDocument) => (
                        <Link
                          to={`/simplify/${eachSimplifiedDocument._id}`}
                          className="simplifyResultEachCard simplifiedEachDocumentCard"
                        >
                          <HiDocumentText className="resultCardIcon" />

                          <div className="simplifyResultCardContent">
                            <p className="resultCardText">
                              {eachSimplifiedDocument.title.substring(0, 40)}...
                            </p>
                          </div>
                        </Link>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          )}

          {fileDropped ? (
            <div className="simplifyDocumentsResultsContainer">
              <div
                className="simplifyDocumentsGoBack"
                onClick={() => {
                  setFileDropped(null);
                  setElements([]);
                }}
              >
                <MdOutlineArrowBack className="simplifyDocumentsGoBackIcon" />
                <p className="simplifyDocumentsGoBackText">Go Back</p>
              </div>

              <div className="simplifyDocumentsResults">
                <div className="simplifyDocumentResultsMainContent">
                  {/* {streamStatus === "PENDING" && ( */}
                  <div className="simplifiedDocumentRightSection">
                    {streamStatus === "PENDING" ? (
                      <div className="streamStatusContainer">
                        <Puff
                          height="15"
                          width="15"
                          radius={1}
                          color="#1253f3"
                          secondaryColor="#1253f340"
                          ariaLabel="puff-loading"
                          wrapperStyle={{}}
                          wrapperClass="streamStatusLoading"
                          visible={true}
                        />
                        <p className="streamStatusContainerText">
                          Simplifying Document... Do not close your browser, the
                          result will be lost
                        </p>
                      </div>
                    ) : (
                      <div className="streamStatusContainer streamStatusSuccessContainer">
                        <BiCheck color="green" />
                        <p className="streamStatusContainerText">
                          Document is simplified and saved for later.
                        </p>
                      </div>
                    )}

                    {/* <Toast.Provider swipeDirection="right">
                      <div
                        className="simplifiedDocumentSaveButton"
                        onClick={() => saveSimplifiedDocument()}
                      >
                        <p className="simplifiedDocumentSaveButtonText">
                          Save for Later
                        </p>

                        {savingDocument && (
                          <Oval
                            height={15}
                            width={15}
                            color="white"
                            wrapperStyle={{ marginLeft: 5 }}
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#ffffff7b"
                            strokeWidth={5}
                            strokeWidthSecondary={5}
                          />
                        )}
                      </div>

                      <Toast.Root
                        className="ToastRoot"
                        open={open}
                        onOpenChange={setOpen}
                      >
                        <div className="toastContainer">
                          <div className="toastIcon">
                            <BiCheck className="documentSavedIcon" />
                          </div>

                          <div className="toastContent">
                            <Toast.Title className="ToastTitle">
                              Document Saved
                            </Toast.Title>
                            <Toast.Description asChild>
                              <p className="ToastDescription">
                                <b>{fileDropped?.name}</b> was saved
                                successfully. You can find it again in the
                                Simplify Screen.
                              </p>
                            </Toast.Description>
                            <Toast.Action
                              className="ToastAction"
                              asChild
                              altText="Goto schedule to undo"
                            >
                              <button
                                onClick={() => setOpen(false)}
                                className="Button small green"
                              >
                                <IoClose />
                              </button>
                            </Toast.Action>
                          </div>
                        </div>
                      </Toast.Root>
                      <Toast.Viewport className="ToastViewport" />
                    </Toast.Provider> */}
                  </div>
                  {/* )} */}

                  <div className="simplifyDocumentsHeader">
                    {fileDropped.name && (
                      <div
                        className="simplifyDocumentsName"
                        onClick={() => {
                          if (fileDropped.id) {
                            window.open(
                              `${window.location.origin}/document/${fileDropped.id}/draft`
                            );
                          }
                        }}
                      >
                        <HiDocumentText className="simplifyDocumentsHeaderNameIcon" />
                        <p className="simplifyDocumentsHeaderNameText">
                          {fileDropped.name}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* <div className="simplifyResultsCards">
                    <div className="simplifyResultEachCard">
                      <BsStars className="resultCardIcon" />

                      <div className="simplifyResultCardContent">
                        <p className="resultCardLabel">Monthly Salary</p>
                        <p className="resultCardText">$400/month</p>
                      </div>
                    </div>

                    <div className="simplifyResultEachCard">
                      <BsStars className="resultCardIcon" />

                      <div className="simplifyResultCardContent">
                        <p className="resultCardLabel">Monthly Salary</p>
                        <p className="resultCardText">$400/month</p>
                      </div>
                    </div>

                    <div className="simplifyResultEachCard">
                      <BsStars className="resultCardIcon" />
                      <div className="simplifyResultCardContent">
                        <p className="resultCardLabel">Probation Period</p>
                        <p className="resultCardText">3 months</p>
                      </div>
                    </div>

                    <div className="simplifyResultEachCard">
                      <BsStars className="resultCardIcon" />
                      <div className="simplifyResultCardContent">
                        <p className="resultCardLabel">Expiration Date</p>
                        <p className="resultCardText">12th Feb 2024</p>
                      </div>
                    </div>
                  </div> */}

                  {/* {JSON.stringify(elements)} */}

                  {elements.map((eachElement) => {
                    if (eachElement.type === "TITLE") {
                      return (
                        <h1 className="simplifyDocumentsHeaderText">
                          {eachElement.text}
                        </h1>
                      );
                    } else if (eachElement.type === "SUBTITLE") {
                      if (eachElement.text.includes("<>")) {
                        return (
                          <div className="simplifyResultsCards">
                            {getSimplifiedCardsData(eachElement.text).map(
                              (eachCardData) => (
                                <div className="simplifyResultEachCard">
                                  <BsStars className="resultCardIcon" />

                                  <div className="simplifyResultCardContent">
                                    <p className="resultCardLabel">
                                      {eachCardData.label}
                                    </p>
                                    <p className="resultCardText">
                                      {eachCardData.data}
                                    </p>
                                  </div>
                                </div>
                              )
                            )}

                            {/* 
                          <div className="simplifyResultEachCard">
                            <BsStars className="resultCardIcon" />
  
                            <div className="simplifyResultCardContent">
                              <p className="resultCardLabel">Monthly Salary</p>
                              <p className="resultCardText">$400/month</p>
                            </div>
                          </div>
  
                          <div className="simplifyResultEachCard">
                            <BsStars className="resultCardIcon" />
                            <div className="simplifyResultCardContent">
                              <p className="resultCardLabel">
                                Probation Period
                              </p>
                              <p className="resultCardText">3 months</p>
                            </div>
                          </div>
  
                          <div className="simplifyResultEachCard">
                            <BsStars className="resultCardIcon" />
                            <div className="simplifyResultCardContent">
                              <p className="resultCardLabel">Expiration Date</p>
                              <p className="resultCardText">12th Feb 2024</p>
                            </div>
                          </div> */}
                          </div>
                        );
                      } else {
                        return (
                          <p
                            className="simplifyResultInfoHeader"
                            id={convertTextToSlug(eachElement.text)}
                          >
                            {eachElement.text}
                          </p>
                        );
                      }
                    } else if (eachElement.type === "PARAGRAPH") {
                      return (
                        <p className="simplifyResultInfoDescription">
                          {eachElement.text}
                        </p>
                      );
                    }
                  })}

                  {streamStatus === "PENDING" && (
                    <div className="skeletonLoader" id="simplifyDocumentLoader">
                      <div className="skeletonLoaderElement skeletonHeader"></div>
                      <div className="skeletonParagraphGroup">
                        <div className="skeletonLoaderElement skeletonParagraph"></div>
                        <div className="skeletonLoaderElement skeletonParagraph"></div>
                        <div className="skeletonLoaderElement skeletonParagraph"></div>
                      </div>
                    </div>
                  )}

                  {/* <div className="simplifyDocumentsResultHeader">
                    <h1 className="simplifyDocumentsHeaderText">
                      Simplified Rajsuthan's Employee Agreement
                    </h1>
                    <p className="simplifyDocumentsDescription">
                      Agreement oulining the responsibilites, payment, and other
                      information to hire Rajsuthan as a contractor.
                    </p>
                  </div>


                  <div className="simplifyResultsContent">
                    <div className="simplifyResultInfoContainer">
                      

                     
                    </div>

                    <div className="simplifyResultInfoContainer">
                      <p className="simplifyResultInfoHeader">
                        Consultant's Services: What Rajsuthan Will Do
                      </p>

                      <p className="simplifyResultInfoDescription">
                        Rajsuthan will provide professional consulting services
                        to Sootchy, Inc. These services include web design and
                        development using Javascript, mobile app design and
                        development using React Native, creating web templates
                        using Photoshop designs and sketches, collaborating with
                        designers and product managers, and other related coding
                        tasks as necessary.{" "}
                      </p>
                    </div>

                    <div className="simplifyResultInfoContainer">
                      <p className="simplifyResultInfoHeader">
                        Payment: How Much Rajsuthan Will Earn
                      </p>

                      <p className="simplifyResultInfoDescription">
                        For his services, Rajsuthan will earn $700 per month,
                        paid bi-weekly ($323.08 per pay period). After three
                        months of successful performance, his monthly pay will
                        increase to $800. However, unless Sootchy, Inc. gives
                        written approval, Rajsuthan won't be reimbursed for any
                        expenses he incurs while performing his services.
                      </p>
                    </div>

                    <div className="simplifyResultInfoContainer">
                      <p className="simplifyResultInfoHeader">
                        Working Relationship: How Rajsuthan and Sootchy, Inc.
                        Will Work Together{" "}
                      </p>

                      <p className="simplifyResultInfoDescription">
                        Rajsuthan is not an employee of Sootchy, Inc. He's an
                        independent contractor, which means he's his own boss.
                        He's also free to provide services to other companies or
                        individuals. However, he can't claim to be an employee,
                        partner, or shareholder of Sootchy, Inc.
                      </p>
                    </div>

                    <div className="simplifyResultInfoContainer">
                      <p className="simplifyResultInfoHeader">
                        Confidentiality: Keeping Company Secrets
                      </p>

                      <p className="simplifyResultInfoDescription">
                        Rajsuthan agrees not to share any confidential and
                        proprietary information or trade secrets of Sootchy,
                        Inc. without the company's prior written consent. He
                        also agrees not to use this information for anything
                        other than consulting with the company. This agreement
                        lasts for three years after the end of the agreement.
                      </p>
                    </div>

                    <div className="simplifyResultInfoContainer">
                      <p className="simplifyResultInfoHeader">
                        In a Nutshell: Quick Takeaways
                      </p>

                      <p className="simplifyResultInfoPoints">
                        <li>
                          Rajsuthan is providing professional consulting
                          services to Sootchy, Inc.
                        </li>
                        <li>
                          He'll earn $700 per month, increasing to $800 per
                          month after three months of successful performance.
                        </li>
                        <li>
                          He's an independent contractor, not an employee of
                          Sootchy, Inc.
                        </li>
                        <li>
                          He agrees to keep Sootchy, Inc.'s confidential
                          information secret for three years after the agreement
                          ends.
                        </li>
                        <li>
                          The agreement lasts until Rajsuthan completes his
                          services, unless either party decides to end it
                          earlier.
                        </li>
                      </p>
                    </div>
                  </div> */}
                </div>

                <div className="simplifyDocumentsContents">
                  <p className="simplifyDocumentsContentsHeader">CONTENTS</p>

                  <div className="simplifyDocumentsContentsList">
                    {elements
                      .filter(
                        (eachElement) =>
                          eachElement.type === "SUBTITLE" &&
                          !eachElement.text.includes("<>")
                      )
                      .map((eachContent) => (
                        <p
                          onClick={() => {
                            scrollToSection(
                              convertTextToSlug(eachContent.text)
                            );
                          }}
                          className="simplifyDocumentsEachContent"
                        >
                          {eachContent.text}
                        </p>
                      ))}

                    {/* <p className="simplifyDocumentsEachContent">
                      Consultant's Services: What Rajsuthan Will Do
                    </p>
                    <p className="simplifyDocumentsEachContent">
                      Payment: How Much Rajsuthan Will Earn
                    </p>
                    <p className="simplifyDocumentsEachContent">
                      Working Relationship: How Rajsuthan and Sootchy, Inc. Will
                      Work Together
                    </p>
                    <p className="simplifyDocumentsEachContent">
                      Confidentiality: Keeping Company Secrets
                    </p>
                    <p className="simplifyDocumentsEachContent">
                      In a Nutshell: Quick Takeaways
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <UploadDocument
              openFileUpload={openFileUpload}
              documentUploading={documentUploading}
              allDocuments={allDocuments}
              documentsLoading={documentsLoading}
              getDraft={getDraft}
            />
          )}
        </div>
      </div>
    </div>
  );
};
