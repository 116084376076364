import React from "react";
import * as Toast from "@radix-ui/react-toast";
import { BiCheck } from "react-icons/bi";
import { IoClose, IoWarning } from "react-icons/io5";

export const ToastComponent = ({
  open,
  setOpen,
  title,
  description,
  direction = "right",
}) => {
  return (
    <>
      <Toast.Provider swipeDirection="down">
        <Toast.Root className="ToastRoot" open={open} onOpenChange={setOpen}>
          <div className="toastContainer">
            <div className="toastIcon">
              {title.includes("[red]") ? (
                <IoWarning color="red" className="documentSavedIcon" />
              ) : (
                <BiCheck className="documentSavedIcon" />
              )}
            </div>

            <div className="toastContent">
              <Toast.Title className="ToastTitle">
                {title.replace("[red]", "").trim()}
              </Toast.Title>
              <Toast.Description asChild>
                <p className="ToastDescription">{description}</p>
              </Toast.Description>
              <Toast.Action
                className="ToastAction"
                asChild
                altText="Goto schedule to undo"
              >
                <button
                  onClick={() => setOpen(false)}
                  className="Button small green"
                >
                  <IoClose />
                </button>
              </Toast.Action>
            </div>
          </div>
        </Toast.Root>
        <Toast.Viewport className="ToastViewport" />
      </Toast.Provider>
    </>
  );
};
