import React, { useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { EachDocument } from "../components/EachDocument";
import {
  deleteDocumentDraftAPI,
  getMyDraftsAPI,
  uploadOwnDocument,
} from "../api/apiCalls";
import { HiDocument } from "react-icons/hi2";
import { updateUser } from "../redux/actions/userAction";
import RadixModal from "../components/Modal/RadixModal";
import { CgAdd } from "react-icons/cg";
import { IoAddCircleSharp } from "react-icons/io5";
import { UploadDocuments } from "../components/UploadDocuments";

export const MyDocuments = () => {
  const [drafts, setDrafts] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);

  const dispatch = useDispatch();

  const getMyDrafts = async () => {
    const response = await getMyDraftsAPI();
    setDrafts(response.data);
  };

  useEffect(() => {
    getMyDrafts();
  }, []);

  const deleteDocument = async (id) => {
    setDeleteLoading(true);
    const response = await deleteDocumentDraftAPI({ id: selectedId });
    if (response.data) {
      setDeleteLoading(false);
      setShowDeleteModal(false);
      setSelectedId("");
      getMyDrafts();
    }
  };

  const { token } = useSelector((state) => state.user);

  const uploadDocument = async () => {
    if (uploadedFiles.length === 0) return;
    setUploadLoading(true);

    const progressArray = uploadedFiles.map(() => false);
    setUploadProgress(progressArray);

    console.log("this is the uploaded files:", uploadedFiles);

    try {
      for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i]; // Correctly access each file in the array
        const formData = new FormData();
        formData.append("document", file);

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/draft/upload-own-document`,
          {
            method: "POST",
            body: formData,
            "Content-Type": "multipart/form-data",
            headers: {
              api_key: "krishna",
              authorization: token,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        console.log("uploading -> ", file);
        console.log(response);

        // if (!response.data) {
        //   throw new Error(`File upload failed for ${file.name}`);
        // }

        // Mark the file as uploaded in the progress array
        progressArray[i] = true;
        setUploadProgress([...progressArray]);
      }

      setUploadLoading(false);
      setShowUploadModal(false);
      setUploadedFiles([]);
      getMyDrafts();
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploadLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    // Ensure files are properly stored
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    setUploadProgress((prevProgress) => [
      ...prevProgress,
      ...files.map(() => false),
    ]);
  };

  const removeFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setUploadProgress((prevProgress) =>
      prevProgress.filter((_, i) => i !== index)
    );
  };

  const currentDocument = (id) => {
    return drafts.filter((draft) => draft._id === id)[0];
  };

  return (
    <div className="myDocumentsContainer">
      <Navbar />

      {showDeleteModal && (
        <RadixModal
          title="Permanent Delete"
          description={
            "This change cannot be reversed. Confirm if you want to proceed."
          }
          buttonText={"Yes, Permanently delete this Document"}
          openModal={showDeleteModal}
          setOpenModal={setShowDeleteModal}
          className="deleteDocumentModal"
          onClick={() => {
            deleteDocument();
          }}
          loading={deleteLoading}
        >
          <div className="deleteDocumentModalContainer">
            <p className="deleteDocumentText">
              Once you permanently delete{" "}
              <b>{currentDocument(selectedId).name}</b>, you will not be able to
              access this document again. Confirm if you want to proceed with
              this.
            </p>
          </div>
        </RadixModal>
      )}

      {showUploadModal && (
        <UploadDocuments
          showUploadModal={showUploadModal}
          setShowUploadModal={setShowUploadModal}
          onSuccess={() => {
            getMyDrafts();
            window.scrollTo(0, document.body.scrollHeight);
          }}
        />
      )}

      <div className="rightContainer">
        <div className="rightContainerHeader">
          <h1 className="rightContainerHeaderText">Your Saved Documents</h1>
          <p className="rightContainerHeaderDescription">
            {drafts && (
              <p className="myDocumentsLengthCount">
                {drafts.length} Documents
              </p>
            )}
          </p>
          <button
            onClick={() => setShowUploadModal(true)}
            className="emptyMyDocumentsButton"
            style={{
              background: "black",
              outline: "none",
              color: "white",
              marginLeft: "30px",
              border: "none !important",
              borderRadius: "30px",
              position: "absolute",
              top: "10px",
              right: "20px",
              fontSize: "12px !important",
              marginTop: "30px",
              display: "flex",
              alignItems: "center",
              outline: "none",
              width: "200px",
            }}
          >
            <IoAddCircleSharp size={14} />
            <p style={{ fontSize: "12px", marginLeft: "5px" }}>
              Upload your own Documents
            </p>
          </button>
        </div>

        {drafts ? (
          drafts.length === 0 ? (
            <div className="emptyMyDocuments">
              <HiDocument className="emptyDocumentsIcon" />
              <p className="emptyMyDocumentsHeader">
                Choose and create your document now
              </p>
              <p className="emptyMyDocumentsDescription">
                Select the document that you need, then start creating it for
                your use-cases.
              </p>
              <div className="emptyButtons">
                <button
                  onClick={() =>
                    dispatch(updateUser({ showDocumentsModal: true }))
                  }
                  className="emptyMyDocumentsButton"
                >
                  Create Document
                </button>
                <button
                  onClick={() => setShowUploadModal(true)}
                  className="emptyMyDocumentsButton"
                  style={{
                    background: "none",
                    outline: "none",
                    color: "black",
                    marginLeft: "30px",
                    border: "none",
                    borderLeft: "1px solid lightgray",
                    borderRadius: "0px",
                    paddingLeft: "25px",
                  }}
                >
                  Upload your own Documents
                </button>
              </div>
            </div>
          ) : (
            <div className="myDocumentsList">
              {drafts.map((eachDocument) => (
                <EachDocument
                  eachDocument={{
                    ...eachDocument,
                    name: eachDocument.name,
                    id: eachDocument._id,
                  }}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  setShowDeleteModal={setShowDeleteModal}
                  deleteLoading={deleteLoading}
                  draft={true}
                  showDelete={true}
                />
              ))}
            </div>
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
};
