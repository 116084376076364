import React, { useEffect, useState } from "react";
import { FiCheck, FiChevronDown, FiChevronRight } from "react-icons/fi";
import { IoAdd, IoArrowBack, IoClose } from "react-icons/io5";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import createDocumentSkeleton from "../createDocumentSkeleton.png";

import { useDispatch, useSelector } from "react-redux";
import {
  createFlowAPI,
  getDocumentAPI,
  getDocumentPaidStatusAPI,
  getDocumentPaymentLinkAPI,
  getUserAPI,
} from "../api/apiCalls";
import { DocumentDetailLoader } from "../components/Loaders/DocumentDetailLoader";
import { updateStepper } from "../redux/actions/stepperActions";
import { BsInfoLg, BsPalette2, BsRocketFill } from "react-icons/bs";
import { Modal } from "../components/Modal";
import * as Checkbox from "@radix-ui/react-checkbox";
import { updateDocument } from "../redux/actions/documentActions";
import { AiFillInfoCircle, AiOutlineExclamation } from "react-icons/ai";
import { Oval } from "react-loader-spinner";
import { convertToCamelCase } from "../functions";
import { MdAdd } from "react-icons/md";
import {
  BiQuestionMark,
  BiRightArrowAlt,
  BiSolidCheckShield,
} from "react-icons/bi";
import { Tooltip } from "../components/Tooltip";
import { paymentPlans } from "../data";
import { updateUser } from "../redux/actions/userAction";

import { animated, easings, useSpring } from "@react-spring/web";

import { GoCheckCircleFill } from "react-icons/go";
import { StartupLocationInput } from "../components/SignupFlowOnboarding/StartupLocationInput";

export const DocumentDetail = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [createDocumentLoading, setCreateDocumentLoading] = useState(false);

  const [expandedBenefits, setExpandedBenefits] = useState([]);

  const { steps, documentDetails } = useSelector((state) => state.stepper);
  const { documentInfo, location: jurisdiction } = useSelector(
    (state) => state.document
  );
  const userData = useSelector((state) => state.user);

  const [documentStatus, setDocumentStatus] = useState(null);

  const [contextText, setContextText] = useState("");

  const [showAddExtraInformation, setShowAddExtraInformation] = useState(false);
  const [extraInfoText, setExtraInfoText] = useState("");

  const [userInfo, setUserInfo] = useState(null);

  const isBenefitExpanded = (id) => {
    return expandedBenefits.filter((benefitId) => benefitId === id)[0];
  };

  const toggleExpandBenefit = (id) => {
    const isBenefitAlreadyExpanded = expandedBenefits.filter(
      (benefitId) => benefitId === id
    )[0];

    if (isBenefitAlreadyExpanded) {
      const newBenefitArray = expandedBenefits.filter(
        (benefitId) => benefitId !== id
      );
      setExpandedBenefits(newBenefitArray);
    } else {
      setExpandedBenefits([...expandedBenefits, id]);
    }
  };

  const [showProcessModal, setShowProcessModal] = useState(false);

  const getAndSaveDocumentSteps = () => {
    setShowProcessModal(true);
  };

  const getDocumentPaidStatus = async (realDocumentDetails) => {
    setLoading(true);

    if (params.id.length !== 0) {
      const response = await getDocumentPaidStatusAPI({
        documentId: params.id,
      });

      if (response.data) {
        getUser(response.data.status, realDocumentDetails);

        if (
          response.data.status !== "FREE_RESOURCE_AVAILABLE_FOR_ACCESS" &&
          response.data.status !== "ACCESS_GRANTED_FOR_DOCUMENT"
        ) {
          setShowBuyDocumentModal(true);
        }
        setDocumentStatus(response.data.status);

        // // //response);
      }
    }
  };

  const getUser = async (passedDocumentStatus, realDocumentDetails) => {
    const response = await getUserAPI();
    if (response.data) {
      setUserInfo(response.data);

      addContextInputs(realDocumentDetails, response.data);

      if (
        response.data.plan === paymentPlans.PRO_PLAN ||
        passedDocumentStatus === "ACCESS_GRANTED_FOR_DOCUMENT" ||
        passedDocumentStatus === "FREE_RESOURCE_AVAILABLE_FOR_ACCESS"
      ) {
        setShowProcessModal(true);
      }
    }
  };

  const [buttonLoading, setButtonLoading] = useState(false);

  const startCreatingProcess = () => {
    setButtonLoading(true);

    dispatch(
      updateStepper({
        warnings: [],
      })
    );

    const areInputsEmpty =
      contextInputs.filter((contextInput) => contextInput.value.length === 0)
        .length !== 0;

    // //contextInputs)
    // //areInputsEmpty)

    // if (areInputsEmpty) {
    //   setError("Add valid answers for all the inputs");
    // } else {
    setError(null);
    let formattedTextProcess = "";

    contextInputs.map((contextInput) => {
      formattedTextProcess += `\n\n ${contextInput.label}: ${contextInput.value}`;
    });

    if (location.country) {
      formattedTextProcess += `\n\n Jurisdiction: ${location.state.name}, ${location.country.name}`;
    }

    if (extraInfoText.length !== 0 && showAddExtraInformation) {
      formattedTextProcess += `\n\n Extra information about ${userData.companyName}: ${extraInfoText}`;
    }

    const locationObject = location.country
      ? {
          state: location?.state?.name,
          country: location?.country?.name,
        }
      : null;

    // console.log(location);

    dispatch(
      updateDocument({
        context: `${formattedTextProcess}`,
        creatingProcess: true,
        ...locationObject,
      })
    );

    // create flow
    // push to /document/${flowId}/create

    const createFlow = async () => {
      const response = await createFlowAPI({
        document: params.id,
        initialInformation: formattedTextProcess,
      });
      if (response.data) {
        setButtonLoading(false);
        navigate(`/document/${response.data.flowID}/create`);
      }
    };

    createFlow();
    // }
  };

  useEffect(() => {
    // console.log("HERE: ", jurisdiction);
  }, [jurisdiction]);

  useEffect(() => {
    dispatch(
      updateStepper({
        steps: [],
        mainStep: 1,
        flowStatus: "",
        currentStep: 1,
      })
    );
  }, []);

  const [contextInputs, setContextInputs] = useState();

  const [error, setError] = useState(null);

  const addContextInputs = (responseData, userData) => {
    let array = [];

    // console.log(documentInfo);

    responseData.help.thingsToInclude.map((thingToInclude) => {
      const isDateInput = thingToInclude.title.split(" ").includes("Date");

      // console.log(thingToInclude);

      if (userData && userData.role !== "LAWYER" && thingToInclude.hidden) {
        //
      } else {
        if (userData.role === "LAWYER") {
          //
        }

        array.push({
          label: thingToInclude.title,
          name: convertToCamelCase(thingToInclude.title),
          value: isDateInput ? new Date().toISOString().substring(0, 10) : "",
          type: isDateInput ? "date" : "text",
          example: thingToInclude.example,
          fillInExample: false,
          hidden: thingToInclude.hidden,
        });
      }
    });

    setLoading(false);

    setContextInputs(array);
  };

  const changeContextInput = (name, value) => {
    let newContextInputs = [];

    contextInputs.map((contextInput) => {
      if (contextInput.name === name) {
        newContextInputs.push({
          ...contextInput,
          value: value,
        });
      } else newContextInputs.push(contextInput);
    });

    // //newContextInputs)

    setContextInputs(newContextInputs);
  };

  const changeContextValues = (name, label, value) => {
    let newContextInputs = [];

    contextInputs.filter((contextInput) => {
      if (contextInput.name === name) {
        newContextInputs.push({
          ...contextInput,
          fillInExample: true,
        });
      } else
        newContextInputs.push({
          ...contextInput,
          fillInExample: true,
        });
    });

    setContextInputs(newContextInputs);
  };

  const getDocument = async () => {
    const response = await getDocumentAPI(params.id);

    // //'response: ', response.data)

    if (response.data) {
      // const stepTitlesArray = response.data.process.steps.map((eachStep) => {
      //   return {
      //     ...eachStep.section,
      //     video: eachStep.video,
      //   };
      // });

      getDocumentPaidStatus(response.data.document);

      dispatch(
        updateStepper({
          steps: [],
          documentDetails: response.data.document,
        })
      );

      dispatch(
        updateDocument({
          documentDetails: response.data.document,
        })
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    getDocument();
  }, [params.id]);

  const [showWhatToInclude, setShowWhatToInclude] = useState(false);

  useEffect(() => {
    if (documentDetails) {
      const parentElement = document.querySelector(".createDocumentDetail");
      parentElement.animate({ scrollTop: 0 }, 0);
    }
  }, [documentDetails]);

  const generatePaymentLink = async (onSuccess) => {
    setCreateDocumentLoading(true);

    const response = await getDocumentPaymentLinkAPI({
      documentId: params.id,
    });

    if (response.data) {
      window.location.href = response.data.url;
      setTimeout(() => {
        setCreateDocumentLoading(false);
      }, 1500);
      if (onSuccess) {
        onSuccess();
      }
    }
  };
  const Typewriter = ({ text, delay }) => {
    const [currentText, setCurrentText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    // Typing logic goes here
    useEffect(() => {
      if (currentIndex < text.length) {
        const timeout = setTimeout(() => {
          setCurrentText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, delay);

        return () => clearTimeout(timeout);
      }
    }, [currentIndex, delay, text]);

    return <p className="findDocumentsInput anim-typewriter">{currentText}</p>;
  };

  const [fillInExample, setFillInExample] = useState(false);

  const [documentLoading, setDocumentLoading] = useState(false);

  useEffect(() => {
    if (fillInExample === true && documentDetails) {
      setContextText(documentDetails.help.example);
    }
  }, [fillInExample]);

  useEffect(() => {
    if (searchParams.get("buy") === "true" && documentStatus) {
      if (documentStatus === "NO_ACCESS_FOR_DOCUMENT") {
        setDocumentLoading(true);
        generatePaymentLink(() => {
          setDocumentLoading(false);
        });
      }
    }
  }, [documentStatus]);

  const fillExampleAndAddInputText = (name, changedContextInput) => {
    let newContextInputs = [];

    contextInputs.filter((contextInput) => {
      if (contextInput.name === name) {
        newContextInputs.push({
          ...contextInput,
          ...changedContextInput,
        });
      } else newContextInputs.push(contextInput);
    });

    setContextInputs(newContextInputs);
  };

  const [showBuyDocumentModal, setShowBuyDocumentModal] = useState(false);

  const [buyDocumentModalProps, setBuyDocumentModalProps] = useSpring(() => ({
    opacity: 1,
  }));

  const closeBuyDocumentModal = () => {
    setBuyDocumentModalProps({
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
    });

    setTimeout(() => {
      setShowBuyDocumentModal(false);
    }, 500);
  };

  const [location, setLocation] = useState({});

  return (
    <div className="createDocumentDetail">
      {documentLoading && (
        <div className="documentLoadingContainer">
          <Oval
            height={25}
            width={25}
            color="#1253f3"
            wrapperStyle={{}}
            wrapperClass="modalMainContentButtonLoader"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1253f340"
            strokeWidth={5}
            strokeWidthSecondary={5}
          />
        </div>
      )}

      {showProcessModal && (
        <Modal
          modalClassName="explainBusinessModal"
          onClose={() => {
            setShowProcessModal(!showProcessModal);
          }}
          HeaderIcon={BsPalette2}
          // headerText={"Provide some Information to create your process"}
          headerText={"Answer some questions before we proceed"}
          description="Provide some information to start creating this document"
          onClick={() => {
            startCreatingProcess();
          }}
          loading={buttonLoading}
          buttonText="Prepare Document"
          headerClassName="explainBusinessModalHeader"
          showButtonContainer={true}
          showButtonArrow={true}
          // description="Once your provide the information, the process will be customised for your specific scenario for better accuracy and ease. The more context you give, the better the quality of the document will be."
        >
          <div className="modalExplainContent">
            <div className="contextInputsContainer">
              {contextInputs.length > 2 ? (
                <div className="contextInputContainer">
                  <p className="contextInputLabel">Start Date</p>
                  <input
                    type="date"
                    className="contextInput"
                    value={
                      contextInputs.find((input) => input.type === "date")
                        ?.value || ""
                    }
                    placeholder="Select a date"
                    onChange={(e) =>
                      changeContextInput(
                        contextInputs.find((input) => input.type === "date")
                          ?.name || "",
                        e.target.value
                      )
                    }
                  />
                </div>
              ) : (
                contextInputs.map((eachContextInput) => (
                  <div className="contextInputContainer">
                    <p className="contextInputLabel">
                      {eachContextInput.label}
                    </p>
                    {eachContextInput.type === "date" ? (
                      <input
                        type="date"
                        className="contextInput"
                        value={eachContextInput.value}
                        placeholder={eachContextInput.label}
                        onChange={(e) =>
                          changeContextInput(
                            eachContextInput.name,
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      <>
                        <textarea
                          className="contextInput"
                          value={eachContextInput.value}
                          placeholder={`ex: ${eachContextInput.example}`}
                          onChange={(e) =>
                            changeContextInput(
                              eachContextInput.name,
                              e.target.value
                            )
                          }
                        />
                        <div className="checkBoxContainer">
                          <Checkbox.Root
                            checked={eachContextInput.fillInExample}
                            onCheckedChange={(event) => {
                              if (event === false) {
                                fillExampleAndAddInputText(
                                  eachContextInput.name,
                                  {
                                    value: "",
                                    fillInExample: false,
                                  }
                                );
                              } else {
                                fillExampleAndAddInputText(
                                  eachContextInput.name,
                                  {
                                    value: eachContextInput.example,
                                    fillInExample: true,
                                  }
                                );
                              }
                            }}
                            className="CheckboxRoot"
                            defaultChecked
                            id="c1"
                          >
                            <Checkbox.Indicator className="CheckboxIndicator">
                              <FiCheck />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <label className="Label" htmlFor="c1">
                            Fill in an example
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                ))
              )}

              {userInfo.role === "LAWYER" && (
                <div
                  className="contextInputContainer"
                  style={{ marginTop: 0, paddingTop: "0px" }}
                >
                  {/* <p className="contextInputLabel">Jurisdiction</p> */}

                  <StartupLocationInput
                    value={location}
                    changeInput={setLocation}
                  />
                </div>
              )}

              {error && (
                <p className="stepperErrorText contextInputError">
                  <AiOutlineExclamation className="contextInputErrorIcon" />
                  <p className="stepperErrorTextLabel">{error}</p>
                </p>
              )}

              {!showAddExtraInformation ? (
                <div
                  className="addMoreDetailsContainer"
                  onClick={() => setShowAddExtraInformation(true)}
                >
                  <MdAdd className="addIcon" />
                  <p className="addMoreDetailsText">Add extra information</p>

                  <Tooltip
                    text={`This can be any extra information that you want to add about ${userData.companyName} - any changes to your startup or any extra information you would want to include in this document.`}
                  >
                    <p className="toolTipText">What to add?</p>
                  </Tooltip>
                </div>
              ) : (
                <div className="addMoreDetailsContainer extraInfoInputContainer">
                  <p className="addMoreDetailsText contextInputLabel">
                    Add extra information
                  </p>
                  <IoClose
                    className="addMoreDetailsCloseIcon contextInputLabel"
                    onClick={() => {
                      setExtraInfoText("");
                      setShowAddExtraInformation(false);
                    }}
                  />

                  <textarea
                    value={extraInfoText}
                    onChange={(e) => setExtraInfoText(e.target.value)}
                    className="contextInput"
                    placeholder="Add any extra information relevant for creating this document."
                  ></textarea>
                </div>
              )}
            </div>
            {/* <textarea
              autoFocus
              className="findDocumentsInput"
              placeholder={`Our Startup is called Acme. We provide home services to people in the neighbourhood, this includes services like , plumbing, car washing etc. We operate from California (US), and the workers are paid on an hourly basis. We have a mobile app and a website.`}
              value={contextText}
              onChange={(e) => setContextText(e.target.value)}
            ></textarea> */}

            {/* <Typewriter
              text="Our Startup is called CloudPro. We provide cloud-based software solutions to businesses in the technology sector. This includes services like project management tools, CRM software, and data analytics platforms. We operate globally, with headquarters in Silicon Valley, California. We have a mobile app and a website where clients can access our suite of software solutions, request demos, and subscribe to our services on a subscription basis."
              delay={12}
            /> */}

            {/* <p className="findDocumentsInput anim-typewriter">
              Our Startup is called Acme. We provide home services to people in
              the neighbourhood, this includes services like , plumbing, car
              washing etc. We operate from California (US), and the workers are
              paid on an hourly basis. We have a mobile app and a website.
            </p> */}
            {/* 
            <div className="checkBoxContainer">
              <Checkbox.Root
                checked={fillInExample}
                onCheckedChange={(event) => {
                  if (event === false) {
                    setContextText("");
                  }
                  setFillInExample(!fillInExample);
                }}
                className="CheckboxRoot"
                defaultChecked
                id="c1"
              >
                <Checkbox.Indicator className="CheckboxIndicator">
                  <FiCheck />
                </Checkbox.Indicator>
              </Checkbox.Root>
              <label className="Label" htmlFor="c1">
                Fill in an example
              </label>
            </div> */}

            {/* <div className="whatToInclude">
              <FiChevronDown
                className={`whatToIncludeIcon ${
                  showWhatToInclude && "whatToIncludeIconOpen"
                }`}
                onClick={() => {
                  setShowWhatToInclude(!showWhatToInclude);
                }}
              />

              <div className="whatToIncludeContent">
                <p
                  className="whatToIncludeContentTitle"
                  onClick={() => {
                    setShowWhatToInclude(!showWhatToInclude);
                  }}
                >
                  What to Include in the Answer?
                </p>

                {showWhatToInclude && (
                  <div className="whatToIncludeContentPoints">
                    {documentDetails.help.thingsToInclude.map(
                      (thingToInclude) => (
                        <li className="whatToIncludeContentEachPoint">
                          {thingToInclude}
                        </li>
                      )
                    )}
                  </div>
                )}
              </div>
            </div> */}
          </div>
        </Modal>
      )}

      <div className="createDocumentDetailLeft">
        <IoArrowBack
          className="documentDetailBackIcon"
          onClick={() => {
            navigate("/browse-documents");
          }}
        />

        <img
          src={createDocumentSkeleton}
          className="createDocumentDetailSkeleton"
        />
      </div>

      <div className="createDocumentDetailRight">
        {loading ? (
          <DocumentDetailLoader />
        ) : (
          <>
            <p className="documentDetailHeaderText">
              Create a {documentDetails.title}
            </p>
            <p className="documentDetailHeaderDescription">
              {documentDetails.description}
            </p>

            <div className="documentDetailBenefits">
              <p className="documentDetailBenefitsHeaderText">
                Benefits of Having a {documentDetails.title}
              </p>

              <div className="documentBenefitsList">
                {documentDetails.benefits.map((benefit) => (
                  <div className="documentBenefit">
                    <div
                      className="documentBenefitHeader"
                      onClick={() => toggleExpandBenefit(benefit._id)}
                    >
                      <p
                        className={`documentBenefitTitle ${
                          isBenefitExpanded(benefit._id) &&
                          "expandedBenefitTitle"
                        }`}
                      >
                        {benefit.title}
                      </p>
                      <FiChevronDown
                        className={`documentBenefitExpandIcon ${
                          isBenefitExpanded(benefit._id) &&
                          "expandedBenefitIcon"
                        }`}
                      />
                    </div>
                    {isBenefitExpanded(benefit._id) && (
                      <p className="documentMoreInfo">{benefit.description}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* <div className="documentDetailTip">
              <p className="documentTipIcon">💡</p>
              <p className="documentDetailTipText">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque,
                neque cupiditate explicabo molestias ipsa veniam sit aliquam
                asperiores distinctio nesciunt.
              </p>
            </div> */}

            {/* {JSON.stringify(documentStatus)}
            {JSON.stringify(documentStatus !== "ACCESS_GRANTED_FOR_DOCUMENT")}
            {JSON.stringify(userInfo.plan)}
            {JSON.stringify(showBuyDocumentModal)} */}

            {/* {JSON.stringify(documentStatus)} */}

            {showBuyDocumentModal &&
              (userInfo.plan !== paymentPlans.PRO_PLAN ||
                documentStatus !== "ACCESS_GRANTED_FOR_DOCUMENT") &&
              documentStatus !== "FREE_RESOURCE_AVAILABLE_FOR_ACCESS" && (
                <div className="appOnboardingModal">
                  <animated.div
                    style={buyDocumentModalProps}
                    className={`appOnboardingModalContent`}
                  >
                    <div className="appOnboardingContent">
                      <div className="appOnboardingSuccessHeaderContent">
                        <h1 className="appOnboardingSuccessHeader">
                          Purchase this Document
                        </h1>

                        <p className="appOnboardingSuccessDescription">
                          Get this document and start creating it or upgrade to
                          the pro plan to get unlimited documents and features.
                        </p>
                      </div>

                      <div
                        className="appOnboardingMain"
                        style={{ paddingTop: "0" }}
                      >
                        <p className="appOnboardingSuccessDescription">
                          Once you buy the document, you can start answering
                          simple and personalised questions - get the document
                          created with best-in class AI and unlock more
                          features.
                        </p>

                        <div className="buyDocumentUsersSection">
                          <div className="buyDocumentUsersAvatars">
                            <div className="buyDocumentEachUserAvatar">🤩</div>
                            <div className="buyDocumentEachUserAvatar">🥳</div>
                            <div className="buyDocumentEachUserAvatar">👨‍💼</div>
                            <div className="buyDocumentEachUserAvatar">🧑‍💻</div>
                            <div className="buyDocumentEachUserAvatar">🧑🏻‍🎨</div>
                          </div>

                          <p
                            className="appOnboardingSuccessDescription"
                            style={{ margin: "0" }}
                          >
                            Join over 100+ happy users
                          </p>
                        </div>

                        <div className="buyDocumentModalButtons">
                          <p className="refundableText">
                            <BiSolidCheckShield className="refundableTextIcon" />
                            <p>
                              <a
                                href="https://www.notion.so/Airstrip-AI-Refund-Policy-88db05bd702b40c7a795d3a09948cb70?pvs=4#4f56139a6a424a85bec5650511d37fc8"
                                target="_blank"
                                className="font-bold refundableTextMain"
                              >
                                100% Refund
                              </a>{" "}
                              If results are not accurate
                            </p>
                          </p>
                          <div
                            className={`upgradeToProButton hoverButton ${
                              createDocumentLoading && "disabledButton"
                            }`}
                            onClick={() => generatePaymentLink()}
                          >
                            <div className="upgradeToProButtonText">
                              Buy this Document{" "}
                              <span className="upgradeToProButtonLabel">
                                — $30 (60% choose this)
                              </span>{" "}
                            </div>

                            {createDocumentLoading && (
                              <Oval
                                height={12}
                                width={12}
                                color="#ffffff"
                                wrapperStyle={{ marginLeft: 5 }}
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#ffffff30"
                                strokeWidth={5}
                                strokeWidthSecondary={5}
                              />
                            )}
                          </div>
                          <div
                            onClick={() => {
                              dispatch(
                                updateUser({
                                  showUpgradeModal: true,
                                })
                              );
                            }}
                            className="secondaryUpgradeButton hoverButton"
                          >
                            <div className="upgradeToProButtonText">
                              Upgrade to Pro
                            </div>
                            {/* <BsRocketFill className="upgradePlanButtonIcon" /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </animated.div>

                  <div
                    className="appOnboardingModalOverlay"
                    onClick={() => closeBuyDocumentModal()}
                  ></div>
                </div>
              )}
            {/* {JSON.stringify(userInfo.plan !== paymentPlans.PRO_PLAN)}
            {JSON.stringify(documentStatus !== "ACCESS_GRANTED_FOR_DOCUMENT")} */}

            {userInfo && documentStatus ? (
              userInfo.plan === paymentPlans.PRO_PLAN ||
              documentStatus === "ACCESS_GRANTED_FOR_DOCUMENT" ? (
                <div className="createDocumentLastSection">
                  <button
                    className={`createDocumentButton ${
                      createDocumentLoading && "disabledButton"
                    }`}
                    onClick={() => getAndSaveDocumentSteps()}
                    disabled={createDocumentLoading}
                  >
                    <p className="createDocumentButtonText">Create Document</p>
                    <FiChevronRight className="createDocumentButtonIcon" />
                  </button>
                </div>
              ) : (
                <div className="upgradePlanOverlay">
                  <BsRocketFill className="upgradePlanButtonIcon" />

                  <div className="upgradePlanOverlayRight">
                    <h1 className="upgradePlanOverlayHeader">
                      {/* Upgrade for best value for money. */}
                      Purchase this Document
                    </h1>
                    <p className="upgradePlanOverlayDescription">
                      Purchase the document to start creating it or Upgrade to
                      the Pro Plan, to get access to unlimited documents and
                      features.
                    </p>

                    <div className="upgradePlanOverlayButtons">
                      <div
                        className={`upgradeToProButton hoverButton ${
                          createDocumentLoading && "disabledButton"
                        }`}
                        onClick={() => generatePaymentLink()}
                      >
                        <div className="upgradeToProButtonText">
                          Buy this Document
                        </div>

                        {createDocumentLoading && (
                          <Oval
                            height={12}
                            width={12}
                            color="#ffffff"
                            wrapperStyle={{ marginLeft: 5 }}
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#ffffff30"
                            strokeWidth={5}
                            strokeWidthSecondary={5}
                          />
                        )}
                      </div>

                      <div className="upgradeOverlayViewPlansButton hoverButton">
                        <div
                          onClick={() => {
                            dispatch(
                              updateUser({
                                showUpgradeModal: true,
                              })
                            );
                          }}
                          className="upgradeOverlayViewPlansButtonText"
                        >
                          Upgrade to Pro
                        </div>
                      </div>

                      {documentStatus ===
                      "FREE_RESOURCE_AVAILABLE_FOR_ACCESS" ? (
                        <div
                          className="useFreeDocumentButton hoverButton"
                          onClick={() => getAndSaveDocumentSteps()}
                          disabled={createDocumentLoading}
                        >
                          <p className="useFreeDocumentButtonText">
                            I'll use my free document
                          </p>

                          <BiRightArrowAlt className="useFreeDocumentButtonIcon" />

                          {createDocumentLoading && (
                            <Oval
                              height={12}
                              width={12}
                              color="#121620"
                              wrapperStyle={{ marginLeft: 5 }}
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#12162030"
                              strokeWidth={5}
                              strokeWidthSecondary={5}
                            />
                          )}
                        </div>
                      ) : (
                        <div
                          className={`purchaseDocumentButton hoverButton ${
                            createDocumentLoading && "disabledButton"
                          }`}
                          onClick={() => generatePaymentLink()}
                        >
                          <p className="purchaseDocumentButtonText">
                            I only want this document
                          </p>
                          <BiRightArrowAlt className="useFreeDocumentButtonIcon" />

                          {createDocumentLoading && (
                            <Oval
                              height={12}
                              width={12}
                              color="#121620"
                              wrapperStyle={{ marginLeft: 5 }}
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#12162030"
                              strokeWidth={5}
                              strokeWidthSecondary={5}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};
