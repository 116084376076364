import { animated } from "@react-spring/web";
import { useEffect, useState } from "react";
import {
  BsFillCheckCircleFill,
  BsPlayCircleFill,
  BsQuestionCircleFill,
  BsStars,
} from "react-icons/bs";
import { IoAdd, IoArrowBack } from "react-icons/io5";
import { SiCodereview } from "react-icons/si";
import { RiSendPlaneFill } from "react-icons/ri";
import { documentChatAPI, getUserAPI } from "../../api/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { convertStringToHTML, convertToCamelCase } from "../../functions";

import { v4 } from "uuid";
import { useParams } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import { useDocumentElementsContext } from "./FinalDocumentContext";
import { updateDocument as updateDocumentReducer } from "../../redux/actions/documentActions";
import { paymentPlans } from "../../data";
import { BiLock, BiPlayCircle } from "react-icons/bi";
import { updateUser } from "../../redux/actions/userAction";
import { Oval } from "react-loader-spinner";
import { MdEditDocument, MdOutlineAdd } from "react-icons/md";
import { FaHandSparkles } from "react-icons/fa6";

let chatExamples = [
  {
    title: "Make Accurate Changes to the document",
    examples: [
      "Updating the doc: We have updated our prices of services to $40",
      "Adding new service: We now provide new services in our product",
      "We can now be liable for charges upto $100 from our service",
    ],
  },
  {
    title: "Clarify your questions",
    examples: [
      "What are the key points mentioned in this document?",
      "What should be improved to make this document more accurate?",
      "Give me a short brief on this document.",
    ],
  },
];

export const FinalDocumentChat = ({
  chatSectionProps,
  toggleChat,
  chatInfo,
  updatingElementId,
  updatingTextStream,
  setUpdatingElementId,
  setUpdatingTextStream,
  updatingElementData,
}) => {
  const icons = {
    question: () => (
      <BsQuestionCircleFill className="finalDocumentChatsHeaderIcon" />
    ),
    overview: () => <SiCodereview className="finalDocumentChatsHeaderIcon" />,
  };

  const { id, versionId } = useParams();
  const dispatch = useDispatch();

  const { documentElementsArray, updateArray: setDocumentElementsArray } =
    useDocumentElementsContext();
  const { documentInfo } = useSelector((state) => state.document);
  const { token, name } = useSelector((state) => state.user);

  const [updatingElementInfo, setUpdatingElementInfo] = updatingElementData;

  const [chatInput, setChatInput] = useState("");
  const [messages, setMessages] = useState([]);

  const [chats, setChats] = useState([]);

  const [streamMessage, setStreamMessage] = useState("");
  const [streamStatus, setStreamStatus] = useState("");

  const scrollChat = () => {
    if (document) {
      const element = document.getElementsByClassName(
        "finalDocumentPopupChats"
      );
      element[0].scrollTo(0, element[0].scrollHeight);
    }
  };

  const updateDocument = (targetId, newParagraph, action = "ADD") => {
    const updatedArray = [];
    let foundTarget = false;

    for (const item of documentElementsArray) {
      if (action === "UPDATE_CLAUSE" && item.id === targetId) {
        updatedArray.push({
          ...item,
          text: newParagraph.text,
        });
        foundTarget = true;
      } else if (item.type === "SUBTITLE" && item.id === targetId) {
        foundTarget = true;
        updatedArray.push(item);
        updatedArray.push(newParagraph);
      } else if (foundTarget && item.type === "SUBTITLE") {
        foundTarget = false;
        updatedArray.push(item);
      } else {
        updatedArray.push(item);
      }
    }

    if (
      foundTarget &&
      action === "ADD" &&
      !updatedArray.includes(newParagraph)
    ) {
      updatedArray.push(newParagraph);
    }

    return updatedArray;
  };

  useEffect(() => {}, []);

  const updateFinalChatObject = (object) => {
    const updatedDocumentArray = updateDocument(object.id, {
      type: "paragraph",
      id: uuidv4(),
      text: object.content,
    });

    setDocumentElementsArray(updatedDocumentArray);
    setUpdatingElementId("");

    dispatch(
      updateDocumentReducer({
        unsavedChanges: true,
      })
    );

    setTimeout(() => {
      let newChats = [...chats];
      newChats.push({
        role: "assistant",
        content: `${object.summary.map((eachSummary) => `${eachSummary}\n\n`)}`,
      });
      setChats(newChats);
      setStreamMessage("");
      setStreamStatus("NOT_STARTED");
    }, 5000);
  };

  const [finalDocumentObject, setFinalDocumentObject] = useState(null);

  let newElement = null;

  let localUpdatingObject = {};

  async function readStream(stream) {
    const reader = stream.getReader();

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        setStreamStatus("COMPLETED");
        focusSearchInput();
        return;
      }

      const textDecoder = new TextDecoder();
      const chunk = textDecoder.decode(value);

      const jsonStrings = chunk.match(/\{[^}]+\}/g) || [];

      for (const jsonString of jsonStrings) {
        try {
          const parsed = JSON.parse(jsonString);
          if (parsed.type === "stream") {
            setStreamMessage((prev) => prev + parsed.data);
            scrollChat();
          }
        } catch (err) {
          console.log("Error processing JSON:", err);
          continue;
        }
      }
    }
  }

  const focusSearchInput = () => {
    if (document) {
      const input = document.getElementById("chatInput");
      setTimeout(function () {
        input?.focus();
      }, 50);
    }
  };

  const getDocumentTexts = () => {};

  const addToChat = async (loadedChats) => {
    setStreamStatus("PENDING");

    setTimeout(() => {
      const element = document.querySelector(".loadingChat");
      if (element) element.scrollIntoView();
    }, 200);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbot/question-document`,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
        body: JSON.stringify({
          draftId: id,
          messages: loadedChats,
          version: versionId
            ? parseInt(versionId) - 1
            : documentInfo?.document_drafts.length - 1,
        }),
      }
    );

    if (response.ok) {
      await readStream(response.body);
    } else {
      setStreamStatus("NOT_STARTED");
    }
  };

  const [userInfo, setUserInfo] = useState(null);

  const getUserInfo = async () => {
    const response = await getUserAPI();
    if (response.data) {
      setUserInfo(response.data);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const chatWithDocument = async (e, needToAddMessage = true) => {
    if (e) e.preventDefault();

    if (chatInfo.length !== 0 && streamStatus !== "PENDING") {
      let loadedChats = [];
      await setChats((prev) => {
        loadedChats = [...prev, { role: "user", content: chatInput }];
        return [...prev, { role: "user", content: chatInput }];
      });
      setChatInput("");
      scrollChat();

      addToChat(loadedChats);
    }
  };

  useEffect(() => {
    getDocumentTexts();

    if (chatInfo) {
      if (chatInfo.data && chatInfo.data.autoSend) {
        let loadedChats = [];
        setChats((prev) => {
          loadedChats = [
            ...prev,
            { role: "user", content: chatInfo.data.question },
          ];
          return [...prev, { role: "user", content: chatInfo.data.question }];
        });
      }

      addToMessages("user", chatInfo.title);
    }
  }, [chatInfo]);

  const addToMessages = (type, text) => {
    setMessages([
      ...messages,
      {
        id: v4(),
        role: type,
        content: text,
      },
    ]);
  };

  const handleSubmit = async (e) => {
    chatWithDocument(e);
  };

  useEffect(() => {
    if (
      streamMessage.length > 0 &&
      streamStatus === "COMPLETED" &&
      (!updatingElementId || updatingElementId?.length === 0)
    ) {
      setChats((prev) => [
        ...prev,
        {
          role: "assistant",
          content: streamMessage,
        },
      ]);
      setStreamMessage("");
      setStreamStatus("NOT_STARTED");
    }
  }, [streamStatus]);

  const [showFullChats, setShowFullChats] = useState(false);

  let timer;
  var scrolling = false;

  function makeTransparent() {
    let overlay = document.getElementById("overlay");

    overlay.style.backgroundColor = "transparent";
  }

  function resetTimer() {
    let overlay = document.getElementById("overlay");

    makeTransparent();
    clearTimeout(timer);
    timer = setTimeout(function () {
      if (!scrolling) {
        overlay.style.backgroundColor = "#191f2fcf";
      }
    }, 1000);
  }

  function startTimer() {
    let overlay = document.getElementById("overlay");

    clearTimeout(timer);
    timer = setTimeout(function () {
      if (!scrolling) {
        overlay.style.backgroundColor = "#191f2fcf";
      }
    }, 1000);
  }

  window.addEventListener("scroll", function () {
    let overlay = document.getElementById("overlay");

    if (overlay) {
      scrolling = true;
      clearTimeout(timer);
      makeTransparent();
      timer = setTimeout(function () {
        scrolling = false;
        overlay.style.backgroundColor = "#191f2fcf";
      }, 1000);
    }
  });

  if (userInfo) {
    return (
      <>
        <animated.div
          style={chatSectionProps}
          className={`finalDocumentRightSectionPopup finalDocumentRightSectionPopupWithChats
          `}
        >
          <div className="finalDocumentPopupChatSection">
            {chats.length === 0 && showFullChats && (
              <div className="getStartedFinalDocumentChat">
                <div className="getStartedFinalDocumentChatContainer">
                  <div className="getStartedFinalDocumentChatHeader">
                    <FaHandSparkles className="getStartedFinalDocumentChatHeaderIcon" />
                    <p className="getStartedFinalDocumentChatHeaderText">
                      Hey, it's Pilot!
                    </p>
                    <p className="getStartedFinalDocumentChatDescription">
                      Here's what I can help you with:
                    </p>
                  </div>

                  {chatExamples.map((eachChatExample) => (
                    <div className="getStartedFinalDocumentSection">
                      <p className="getStartedFinalDocumentChatSectionText">
                        {eachChatExample.title}
                      </p>
                      {eachChatExample.examples.map((eachExample) => (
                        <p
                          className="getStartedFinalDocumentChatSectionText"
                          onClick={() => setChatInput(eachExample)}
                        >
                          <MdOutlineAdd className="getStartedFinalDocumentChatSectionTextIcon" />{" "}
                          <p className="getStartedFinalDocumentChatSectionTextSection">
                            "{eachExample}"
                          </p>
                        </p>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {chats.length !== 0 && showFullChats && (
              <div className="finalDocumentPopupChats">
                {chats.map((eachMessage) => {
                  if (eachMessage.role === "user") {
                    return (
                      <div className="eachChat rightChat">
                        <p className="userChatName">YOU</p>
                        <p className="eachChatText">{eachMessage.content}</p>
                      </div>
                    );
                  } else {
                    return (
                      <>
                        <div className="eachChat leftChat">
                          <p className="userChatName">PILOT</p>
                          <p className="eachChatText">{eachMessage.content}</p>
                        </div>
                      </>
                    );
                  }
                })}
                {streamStatus === "PENDING" && (
                  <div className="eachChat leftChat loadingChat">
                    <p className="userChatName">PILOT</p>
                    <p className="eachChatText">
                      {streamMessage.length === 0
                        ? "Thinking..."
                        : streamMessage}
                    </p>
                    <div className="loadingCircle"></div>
                  </div>
                )}
              </div>
            )}

            <div className="finalDocumentChatInputContainer">
              <form
                onSubmit={(e) => chatWithDocument(e)}
                className="finalDocumentChatInputContent"
              >
                <BsStars className="finalDocumentChatInputIcon" />
                <input
                  type="text"
                  className="finalDocumentChatInput"
                  placeholder={
                    showFullChats
                      ? "Try 'Give me the key points of this document'"
                      : "Hi, I am Pilot, I'll help you with this document"
                  }
                  value={chatInput}
                  onChange={(e) => setChatInput(e.target.value)}
                  onFocus={() => setShowFullChats(true)}
                />
              </form>
            </div>
          </div>
        </animated.div>

        {showFullChats && (
          <div
            id="overlay"
            className="finalDocumentRightSectionOverlay"
            onMouseMove={() => resetTimer()}
            onMouseOut={() => startTimer()}
            onClick={() => {
              if (streamStatus !== "PENDING") {
                setShowFullChats(false);
              }
            }}
          ></div>
        )}
      </>
    );
  }
};
