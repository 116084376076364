import React, { useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import { Oval } from "react-loader-spinner";
import { EachDocument } from "../components/EachDocument";
import { getAllDocumentsAPI } from "../api/apiCalls";
import { useDispatch } from "react-redux";
import { updateDocument } from "../redux/actions/documentActions";
import { iconsForDocumentCategories } from "../data";
import { BsStars } from "react-icons/bs";
import { updateUser } from "../redux/actions/userAction";

export const BrowseDocuments = () => {
  const dispatch = useDispatch();

  const [formattedDocuments, setFormattedDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchDocumentsInput, setSearchDocumentsInput] = useState("");
  const [allDocumentsLoading, setAllDocumentsLoading] = useState(false);
  const [totalDocumentsCount, setTotalDocumentsCount] = useState(0);

  const getAllDocuments = async () => {
    setAllDocumentsLoading(true);

    const response = await getAllDocumentsAPI();

    let documents = [];
    let totalCount = 0;

    if (response.data) {
      setFormattedDocuments(response.data);
      setFilteredDocuments(response.data);

      response.data.map((documentObject) => {
        documentObject.documents.map((eachDocument) => {
          documents.push(eachDocument);
        });
        totalCount += documentObject.documents.length;
      });

      setTotalDocumentsCount(totalCount);

      dispatch(
        updateDocument({
          allDocuments: documents,
        })
      );

      setAllDocumentsLoading(false);
    }
  };

  useEffect(() => {
    getAllDocuments();
  }, []);

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchDocumentsInput(searchValue);

    if (searchValue === "") {
      setFilteredDocuments(formattedDocuments);
    } else {
      const filtered = formattedDocuments
        .map((documentObject) => {
          const filteredDocs = documentObject.documents.filter((doc) => {
            const normalizedTitle = doc.title
              .toLowerCase()
              .replace(/[-\s]/g, "");
            return normalizedTitle.includes(searchValue.replace(/[-\s]/g, ""));
          });
          return filteredDocs.length > 0
            ? { ...documentObject, documents: filteredDocs }
            : null;
        })
        .filter(Boolean);

      setFilteredDocuments(filtered);
    }
  };

  return (
    <div className="browseDocumentsContainer">
      <Navbar />

      <div className="rightContainer">
        <div className="documentsContainer">
          <div className="rightContainerHeader myDocumentsHeader">
            <div className="searchDocumentsContainer">
              <h3>
                Browse from{" "}
                <span className="documentsLength">{totalDocumentsCount}+</span>{" "}
                all Documents
              </h3>
              <p>
                Browse documents that are essential for businesses, freelancers,
                startups, choose what you need, and start creating instantly.
              </p>

              <input
                type="text"
                className="searchDocumentsInput"
                placeholder="Search documents..."
                value={searchDocumentsInput}
                onChange={handleSearch}
              />
              {searchDocumentsInput.length > 0 &&
                filteredDocuments.length > 0 && (
                  <p className="searchResultsText">
                    {filteredDocuments.length} results for '
                    {searchDocumentsInput}'
                  </p>
                )}
            </div>
          </div>

          <div className="documents">
            {allDocumentsLoading ? (
              <div className="documentsLoadingContainer">
                <Oval
                  height={25}
                  width={25}
                  color="#1253f3"
                  wrapperStyle={{}}
                  wrapperClass="modalMainContentButtonLoader"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#1253f340"
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
                <p className="documentsLoadingText">
                  Getting all the documents...
                </p>
              </div>
            ) : filteredDocuments.length === 0 ? (
              <div className="noDocumentsFoundContainer">
                <img
                  src="./empty_illustration.svg"
                  className="noDocumentsImage"
                  alt=""
                />
                <div className="noDocumentsRight">
                  <p className="noDocumentsFoundText">
                    No documents match your search. Please try a different
                    keyword. Or,
                  </p>

                  <div
                    className="noDocumentsRightButton"
                    onClick={() => {
                      dispatch(updateUser({ showDocumentsModal: true }));
                    }}
                  >
                    <BsStars className="iconNoDocs" />
                    <p>Create a Custom Document</p>
                  </div>
                </div>
              </div>
            ) : (
              filteredDocuments?.map((documentObject) => (
                <div className="eachDocumentList" key={documentObject.type}>
                  <div className="eachDocumentListHeader">
                    {iconsForDocumentCategories[documentObject.type]}
                    <p className="eachDocumentListHeaderText">
                      {documentObject.type}
                    </p>
                  </div>

                  <div className="documentsList">
                    {documentObject.documents.map((eachDocument, index) => (
                      <EachDocument
                        key={index}
                        eachDocument={{
                          name: eachDocument.title,
                          id: eachDocument._id,
                        }}
                      />
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
