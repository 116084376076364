import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./pages/App.1";
import "./styles.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { DocumentDetail } from "./pages/DocumentDetail";
import { CreateDocument } from "./pages/CreateDocument";

import store from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { FinalDocument } from "./pages/FinalDocument";
import { ChatWithAI } from "./pages/Chat";
import { MyDocuments } from "./pages/MyDocuments";
import { CreateSignLink } from "./pages/CreateSignLink";
import { Login } from "./pages/Login";
import { Signup } from "./pages/Signup";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { SignupFlowOnboarding } from "./pages/SignupFlowOnboarding";
import { BrowseDocuments } from "./pages/BrowseDocuments";
import { Simplify } from "./pages/Simplify";
import { SimplifiedDocument } from "./pages/SimplifiedDocument";
import { ChatPage } from "./pages/ChatPage";
import { Settings } from "./pages/Settings";
import { VerificationCode } from "./emails/verification-code";
import { WelcomeEmail } from "./emails/welcome-email";
import { ForgotPassword } from "./pages/ForgotPassword";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
  },
  {
    path: "/emails",
    element: (
      <ProtectedRoute>
        <VerificationCode />
      </ProtectedRoute>
    ),
  },
  {
    path: "/pricing",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  // {
  //   path: "/welcome",
  //   element: (
  //     <ProtectedRoute>
  //       <SignupFlowOnboarding />
  //     </ProtectedRoute>
  //   ),
  // },
  // TODO:
  {
    path: "/chat",
    element: (
      <ProtectedRoute>
        <ChatPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/chat/:chatId",
    element: (
      <ProtectedRoute>
        <ChatPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <ProtectedRoute>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: "/browse-documents",
    element: (
      <ProtectedRoute>
        <BrowseDocuments />
      </ProtectedRoute>
    ),
  },
  {
    path: "/simplify",
    element: (
      <ProtectedRoute>
        <Simplify />
      </ProtectedRoute>
    ),
  },
  {
    path: "/simplify/:id",
    element: <SimplifiedDocument />,
  },
  {
    path: "/my-documents",
    element: (
      <ProtectedRoute>
        <MyDocuments />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id",
    element: (
      <ProtectedRoute>
        <DocumentDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/create",
    element: (
      <ProtectedRoute>
        <CreateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/create/details",
    element: (
      <ProtectedRoute>
        <CreateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/create/review",
    element: (
      <ProtectedRoute>
        <CreateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/create/confirm",
    element: (
      <ProtectedRoute>
        <CreateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/create/chat",
    element: (
      <ProtectedRoute>
        <CreateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/draft",
    element: (
      <ProtectedRoute>
        <FinalDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/draft/:versionId",
    element: (
      <ProtectedRoute>
        <FinalDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sign/:signId",
    element: <CreateSignLink />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store().store}>
    <PersistGate loading={null} persistor={store().persistor}>
      <RouterProvider router={router} />
    </PersistGate>
  </Provider>
);
