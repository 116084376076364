import { MdCreateNewFolder, MdPerson4, MdPolicy } from "react-icons/md";
import { FoundersPartnership } from "./CreateDocumentInitialSteps/FoundersPartnership";
import {
  BsBoxes,
  BsBuildingGear,
  BsPeopleFill,
  BsPersonFillAdd,
} from "react-icons/bs";
import { BiHome, BiSolidLockAlt } from "react-icons/bi";
import { PiArrowsInSimpleBold, PiChatsTeardropFill } from "react-icons/pi";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { LuScanLine } from "react-icons/lu";
import { IoBusiness } from "react-icons/io5";

export const paymentPlans = {
  FREE_PLAN: "FREE_PLAN",
  PRO_PLAN: "PRO_PLAN",
};

export const navbarLinks = {
  mainLinks: [
    {
      link: "/chat",
      name: "Pilot",
      icon: PiChatsTeardropFill,
      new: true,
    },
    {
      link: "/browse-documents",
      name: "Browse Documents",
      icon: BsBoxes,
    },
  ],
  allLinks: [
    {
      link: "/my-documents",
      name: "My Drafts",
      icon: HiOutlineDocumentText,
    },
    {
      link: "/simplify",
      name: "Simplify",
      icon: PiArrowsInSimpleBold,
    },
  ],
};

export const documentsList = [
  { id: 1, name: "Articles of association", category: "formation" },
  {
    id: "6470a6914c46f98c7e7f4a0e",
    name: "Founders/Partnership agreement",
    category: "formation",
    description:
      "A Founders' Agreement is a contract that a company's founders enter into that governs their business relationships. The Agreement lays out the rights, responsibilities, liabilities, and obligations of each founder.",
    benefits: [
      {
        id: 1,
        title: "Protect your assets",
        moreInfo:
          "To protect your limited liability partnership, there are a few important things to keep in mind. Firstly, make sure to document clearly how any assets owned by the LLP should be treated and disposed of. This will help ensure that each member knows their rights.",
      },
      {
        id: 2,
        title: "Structure for decision-making",
        moreInfo:
          "It's also important to establish a structure for decision-making that is well-documented and thorough. By doing so, there will be no confusion as to the decision-making process, and this will help demonstrate good corporate governance to customers and suppliers. Additionally, having a clear decision-making process can help you avoid accidental or unwanted liabilities for your business.",
      },
      {
        id: 3,
        title: "Limit risks to your business",
        moreInfo:
          "To limit the risks to your business, you should produce clear guidelines on risk allocation or to what extent members agree to be liable. This will help you avoid costly and time-intensive dispute resolution processes.",
      },
      {
        id: 4,
        title: "A clear exit strategy",
        moreInfo:
          "Having a clear exit strategy is also crucial in case being a member of the limited liability partnership is no longer commercially viable. It's important to rely on well-documented provisions that clearly set out what happens when a member wants to leave the LLP. This will give you peace of mind that you are not making an overly onerous commitment.",
      },
      {
        id: 5,
        title: "Get clarity on your tax liability",
        moreInfo:
          "Lastly, it's important to get clarity on your tax liability. An LLP is a separate legal entity from its members, but it is treated as a traditional partnership for the purposes of tax. Each member is individually liable for tax on their share of the income or profits of the LLP. Therefore, documenting who is in charge of compliance with the LLP’s tax obligations is crucial to avoid penalties for late payments.",
      },
    ],
    tip: "Founders agreement will show the investors that the founders and team are serious about the business and do their best to make the business a success.",
  },
  { id: 3, name: "Employee Contracts", category: "hiring_and_employment" },
  {
    id: 4,
    name: "Employee Share Incentive Schemes",
    category: "hiring_and_employment",
  },
  {
    id: 5,
    name: "Directors Service Agreements",
    category: "hiring_and_employment",
  },
  {
    id: 6,
    name: "Intellectual Property and Assignments",
    category: "ip_protection",
  },
  {
    id: 7,
    name: "NDA and Confidentiality Agreements",
    category: "confidentiality_and_non_disclosure",
  },
  {
    id: 8,
    name: "Contracts with Customers and Suppliers",
    category: "contracts_with_stakeholders",
  },
  {
    id: 9,
    name: "Shareholder and Investor Agreement",
    category: "contracts_with_stakeholders",
  },
  { id: 10, name: "Terms and Conditions", category: "policies_and_terms" },
  { id: 11, name: "Privacy Policy", category: "policies_and_terms" },
  { id: 12, name: "Refund Policy", category: "policies_and_terms" },
];

export const constants = {
  documentTypes: {
    formation: "formation",
    hiring_and_employment: "hiring_and_employment",
    ip_protection: "ip_protection",
    confidentiality_and_non_disclosure: "confidentiality_and_non_disclosure",
    policies_and_terms: "policies_and_terms",
    contracts_with_stakeholders: "contracts_with_stakeholders",
  },
};

export const initialStepsForDocuments = [
  {
    id: "6470761d41ed28c068571bad",
    name: "Founders Agreement",
    component: FoundersPartnership,
  },
];

export const newNamesForBasicInfo = {
  businessPurpose: "Purpose of the partnership",
};

export const iconsForDocumentCategories = {
  Formation: <MdCreateNewFolder className="eachDocumentListHeaderIcon" />,
  "Governance and Ownership": (
    <MdPerson4 className="eachDocumentListHeaderIcon" />
  ),
  "Policies and Terms": <MdPolicy className="eachDocumentListHeaderIcon" />,
  Hiring: <BsPersonFillAdd className="eachDocumentListHeaderIcon" />,
  "Contracts with Stakeholders": (
    <BsPeopleFill className="eachDocumentListHeaderIcon" />
  ),
  "Confidentiality and Non-Disclosure": (
    <BiSolidLockAlt className="eachDocumentListHeaderIcon" />
  ),
  "Agencies or Service Providers": (
    <IoBusiness className="eachDocumentListHeaderIcon" />
  ),
  Startups: <BsBuildingGear className="eachDocumentListHeaderIcon" />,
};

export const defaultChatRecommendations = (companyName, country) => {
  return [
    `What documents do I need for hiring a new founder?`,
    `What are the laws and regulations for operating ${companyName} in ${country}?`,
    `How do I protect my intellectual property?`,
    `What are the tax implications for ${companyName}?`,
  ];
};

export const chatRecommendationsDuringProcess = [
  "Could you explain the question in simple terms?",
  "Help me select the best option",
  "Is there any mistake in my answer?",
  "Generate more options",
];

export const flowProcessStatuses = {
  CREATED_FLOW: "CREATED_FLOW",
  INITIAL_INFORMATION_PROCESSING: "INITIAL_INFORMATION_PROCESSING",
  INITIAL_INFORMATION_COMPLETED: "INITIAL_INFORMATION_COMPLETED",
  PLAN_PROCESSING: "PLAN_PROCESSING",
  REVIEW_NEEDED: "REVIEW_NEEDED",
  REVIEW_COMPLETED: "REVIEW_COMPLETED",
  DRAFT_COMPLETED: "DRAFT_COMPLETED",
};
