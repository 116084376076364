import React, { useEffect, useState } from "react";
import { MdClear, MdEmojiObjects } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { SearchDocumentsPopup } from "../SearchDocumentsPopup";
import { documents } from "../../pages/App";
import { FiLogOut, FiSettings } from "react-icons/fi";
import {
  BsBoxes,
  BsChatRightTextFill,
  BsRocketFill,
  BsStars,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/actions/userAction";
import { Crisp } from "crisp-sdk-web";
import { AiTwotoneBuild } from "react-icons/ai";
import { IoSend, IoSettings, IoSettingsSharp } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
import { PiArrowsInSimpleBold, PiChatsTeardropFill } from "react-icons/pi";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { LuChevronDown, LuScanLine } from "react-icons/lu";
import { BiHome, BiSupport } from "react-icons/bi";
import { navbarLinks, paymentPlans } from "../../data";
import { RiEmojiStickerFill, RiSettingsFill } from "react-icons/ri";
import { TbDiscount2 } from "react-icons/tb";
import { getPendingFlowsAPI } from "../../api/apiCalls";
import { addNewValue, updateStepper } from "../../redux/actions/stepperActions";

export const Navbar = () => {
  const navigate = useNavigate();

  const [searchDocumentsInput, setSearchDocumentsInput] = useState("");

  const [showAccountModal, setShowAccountModal] = useState(false);

  const user = useSelector((state) => state.user);
  const { pendingFlows } = useSelector((state) => state.stepper);

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(
      updateUser({
        token: null,
        email: null,
        name: null,
      })
    );
    if (Crisp) {
      Crisp?.session.reset();
      Crisp?.chat.hide();
    }

    localStorage.clear();
  };

  const isCurrentLink = (link) => {
    if (
      link === window.location.pathname ||
      (link !== "/" && window.location.pathname.includes(link)) ||
      (link === "/" && window.location.pathname === "/")
    ) {
      return true;
    }
  };

  const getHoursRemaining = () => {
    var currentDate = new Date();

    // Specify the target date (November 24)
    var targetDate = new Date(
      currentDate.getFullYear(),
      10 /* November is 0-based in JavaScript */,
      24
    );

    // Calculate the time difference in milliseconds
    var timeDifference = targetDate - currentDate;

    // Convert the time difference to hours
    var hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference.toFixed(0);
  };

  const getPendingFlows = async () => {
    const response = await getPendingFlowsAPI();

    if (response.data) {
      // console.log("pending flows", response.data);
      dispatch(
        updateStepper({
          pendingFlows: response.data,
        })
      );
    }
  };

  useEffect(() => {
    if (!pendingFlows) {
      dispatch(addNewValue());
      getPendingFlows();
    } else {
      getPendingFlows();
    }
  }, []);

  return (
    <div className="navbarContainer">
      <div className="container" style={{ height: "100%" }}>
        <div className="navbar">
          <div className="navbarLogoContent" onClick={() => navigate("/")}>
            <img src="/logoWhiteTransparent.png" className="logoImage" />
            <p className="logoText">Airstrip</p>

            {pendingFlows && pendingFlows?.length !== 0 && (
              <div className="notificationsLength">{pendingFlows.length}</div>
            )}
          </div>

          <div className="navLinks">
            <div className="mainLinksContainer">
              <div
                className="mainLinkContainer activeMainLinkContainer"
                onClick={() => {
                  dispatch(updateUser({ showDocumentsModal: true }));
                }}
              >
                <BsStars className="mainLinkIcon" />
                <p className="mainLinkText">Create New Document</p>
              </div>

              {navbarLinks.mainLinks.map((eachLink) => (
                <div
                  className={`mainLinkContainer ${
                    isCurrentLink(eachLink.link) && "currentLinkContainer"
                  }`}
                  onClick={() => navigate(eachLink.link)}
                >
                  {eachLink.name === "Pilot" ? (
                    <img
                      src="./piloticon.png"
                      style={{ width: "15px", height: "15px" }}
                    />
                  ) : (
                    <eachLink.icon className="navLinkIcon" />
                  )}
                  <p className="navLink">{eachLink.name}</p>
                  {eachLink.new && <div className="newBadge">New</div>}
                </div>
              ))}
            </div>

            {navbarLinks.allLinks.map((eachLink) => (
              <div
                className={`navLinkContainer ${
                  isCurrentLink(eachLink.link) && "currentLinkContainer"
                }`}
                onClick={() => navigate(eachLink.link)}
              >
                <eachLink.icon className="navLinkIcon" />
                <p className="navLink">{eachLink.name}</p>
              </div>
            ))}

            {/* <div className="navLinkContainer">
              <HiOutlineDocumentText className="navLinkIcon" />
              <p className="navLink" onClick={() => navigate("/my-documents")}>
                My Documents
              </p>
            </div>
            <div
              className="navLinkContainer"
              onClick={() => navigate("/review-and-rewrite")}
            >
              <LuScanLine className="navLinkIcon" />
              <p className="navLink">Review & Rewrite</p>
            </div>
            <div
              className="navLinkContainer"
              onClick={() => navigate("/simplify")}
            >
              <PiArrowsInSimpleBold className="navLinkIcon" />
              <p className="navLink">Simplify</p>
            </div> */}
            {/* <div className="circleDivider"></div>
              <p className="navLink">Startup Wiki</p> */}
            {/* <div className="circleDivider"></div> */}
          </div>

          <div className="navbarRight">
            {/* <div
              className={`searchDocumentsInputContainer ${searchDocumentsInput.length !== 0 &&
                "activeSearchDocumentsInputContainer"
                }`}
            >
              <input
                type="text"
                className="searchDocumentsInput"
                placeholder="Search for Documents"
                value={searchDocumentsInput}
                onChange={(e) => setSearchDocumentsInput(e.target.value)}
              />

              {searchDocumentsInput.length > 0 && (
                <>
                  <MdClear
                    className="searchDocumentsButton"
                    onClick={() => setSearchDocumentsInput("")}
                  />

                  <SearchDocumentsPopup
                    searchText={searchDocumentsInput}
                    documents={documents}
                  />
                </>
              )}
            </div> */}

            <div className="accountInfo">
              {user.plan === paymentPlans.FREE_PLAN && (
                <>
                  <div
                    className="navLinkContainer"
                    onClick={() => {
                      dispatch(
                        updateUser({
                          showAppSumoModal: true,
                        })
                      );
                    }}
                  >
                    <img
                      src="/appsumo_icon.png"
                      style={{ width: "20px", height: "20px" }}
                      className="appsumoLogo"
                    />
                    <p className="navLink">Are you from AppSumo?</p>
                  </div>

                  <div className="accountInfoPlan">
                    <p className="accountInfoPlanTextLabel">Current Plan</p>
                    <p className="accountInfoPlanText">
                      {user.plan === "FREE_PLAN" ? "Free" : "Pro"}
                    </p>

                    {user.plan === paymentPlans.FREE_PLAN && (
                      <div className="accountInfoPlanButtons">
                        <div
                          className="upgradePlanButton"
                          onClick={() => {
                            dispatch(
                              updateUser({
                                showUpgradeModal: true,
                              })
                            );
                          }}
                        >
                          <p className="upgradePlanButtonText">
                            Upgrade to{" "}
                            <span className="proText">Airstrip Pro</span>
                          </p>
                          <BsRocketFill className="upgradePlanButtonIcon" />
                        </div>

                        <div
                          className="accountInfoPlanMainButton"
                          onClick={() => {
                            dispatch(
                              updateUser({
                                showUpgradeModal: true,
                              })
                            );
                          }}
                        >
                          <p className="accountInfoPlanMainButtonText">
                            View Plans
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* <div className="accountInfoPlan">
                <p
                  className="accountInfoPlanText"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TbDiscount2 fontSize={20} style={{ marginRight: "4px" }} />{" "}
                  <p>Black Friday 🎉</p>
                </p>
                <p
                  className="accountInfoPlanTextLabel annualProAccountInfoPlanTextLabel"
                  style={{ lineHeight: "1.5em", fontSize: 13, marginTop: 3 }}
                >
                  Our Black Friday sale starts now! Get 55% off your annual plan
                  before it drops to 30%
                </p>

                <div className="accountInfoPlanButtons">
                  <div
                    className="upgradePlanButton annualUpgradePlanButton"
                    onClick={() => {
                      window.location.href =
                        "https://app.useairstrip.com/pricing?plan=pro";
                    }}
                  >
                    <p className="upgradePlanButtonText">
                      Upgrade and Get 55% off →
                    </p>
                  </div>
                </div>

                <p className="accountInfoPlanExpire">
                  Expires in {getHoursRemaining()} Hours
                </p>
              </div> */}

              {/* <div
                className={`navLinkContainer ${
                  isCurrentLink("settings") && "currentLinkContainer"
                }`}
                onClick={() => navigate("/settings")}
              >
                <IoSettings className="navLinkIcon" />
                <p className="navLink">Settings</p>
              </div> */}

              {/* {user.plan === paymentPlans.PRO_PLAN && (
                <div
                  className={`navLinkContainer`}
                  onClick={() => Crisp.chat.open()}
                >
                  <BiSupport className="navLinkIcon" />
                  <p className="navLink">Contact support</p>
                </div>
              )} */}

              <a
                className={`navLinkContainer`}
                // onClick={() => Crisp.chat.open()}
                href={`https://tally.so#tally-open=m6jeDk&tally-layout=modal&tally-width=600&tally-emoji-text=👋&tally-emoji-animation=wave&name=${user.name}&email=${user.email}`}
              >
                <MdEmojiObjects className="navLinkIcon" />
                <p className="navLink">Add your Feedback</p>
              </a>

              <div
                className={`accountInfoContent ${
                  showAccountModal && "openedAccountInfoContent"
                }`}
                onClick={() => setShowAccountModal(!showAccountModal)}
              >
                <div className="accountInfoLeft">
                  <p
                    className={`${
                      showAccountModal && "openedAccountIcon"
                    } accountIcon ${
                      user.plan === paymentPlans.PRO_PLAN && "proAccountIcon"
                    }`}
                  >
                    {user.email[0]}
                    {user.email[1]}
                  </p>
                </div>

                <div className="accountInfoRight">
                  <p className="accountInfoName">
                    {user.name}{" "}
                    {user.plan === paymentPlans.PRO_PLAN && (
                      <div className="proPlanText">Pro</div>
                    )}{" "}
                  </p>
                  <p className="accountInfoCompany">{user.companyName}</p>

                  <LuChevronDown className="accountInfoDownIcon" />
                </div>
              </div>

              {showAccountModal && (
                <div className="accountInfoModal">
                  <div className="accountInfoModalHeader">
                    <p className="accountName">{user.companyName}</p>
                    <p className="accountEmail">{user.email}</p>
                  </div>

                  <div
                    style={{ textDecoration: "none" }}
                    onClick={() => navigate("/settings")}
                    className="accountInfoLogout"
                  >
                    <IoSettingsSharp className="accountInfoLogOutIcon" />

                    <p className="accountInfoLogoutText">Settings</p>
                  </div>

                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://tally.so#tally-open=m6jeDk&tally-layout=modal&tally-width=600&tally-emoji-text=👋&tally-emoji-animation=wave&name=${user.name}&email=${user.email}`}
                    className="accountInfoLogout"
                  >
                    <MdEmojiObjects className="accountInfoLogOutIcon" />

                    <p className="accountInfoLogoutText">Send Feedback</p>
                  </a>

                  {/* {user.plan !== paymentPlans.PRO_PLAN && (
                    <div
                      className="accountInfoLogout"
                      onClick={() => {
                        // // //user)

                        // Crisp.session.setData({ token: user.token })
                        Crisp.chat.show();
                        Crisp.chat.open();
                      }}
                    >
                      <BsChatRightTextFill className="accountInfoLogOutIcon" />

                      <p className="accountInfoLogoutText">Contact Support</p>
                    </div>
                  )} */}

                  <div
                    className="accountInfoLogout signoutButton"
                    onClick={() => logout()}
                  >
                    <FiLogOut className="accountInfoLogOutIcon" />

                    <p className="accountInfoLogoutText">Sign out</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div className="welcomeSection">
            <div className="welcomeText">Hello, Rajsuthan</div>
          </div> */}
      </div>
    </div>
  );
};
