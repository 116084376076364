import React from "react";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  RocketIcon,
} from "@radix-ui/react-icons";
import "../../select.css";
import { BiRocket } from "react-icons/bi";
import { BsRocketTakeoff } from "react-icons/bs";
import { FaSatellite, FaSpaceShuttle, FaMeteor } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateUser } from "../../redux/actions/userAction";

const SelectDropdown = ({
  values,
  placeholder,
  defaultValue,
  value,
  onValueChange,
  valueLabel,
  textLabel,
  style,
}) => {
  // Check if any value contains "Pro" in its label
  const hasProOption = values.some((eachValue) =>
    eachValue[textLabel].includes("(Pro)")
  );

  const dispatch = useDispatch();

  return (
    <Select.Root
      defaultValue={defaultValue}
      value={value}
      onValueChange={onValueChange}
    >
      <Select.Trigger className="SelectTrigger">
        <Select.Value placeholder="Select" className="SelectPlaceholder" />
        <Select.Icon className="SelectIcon">
          <ChevronDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className="SelectContent">
          <Select.ScrollUpButton className="SelectScrollButton">
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          {hasProOption && (
            <div
              className="proBanner"
              onClick={() => {
                onValueChange("gpt-4o");
                dispatch(
                  updateUser({
                    showUpgradeModal: true,
                  })
                );
              }}
            >
              Upgrade for better models
            </div>
          )}
          <Select.Viewport className="SelectViewport">
            <Select.Group>
              {values.map((eachValue) => {
                const [label, description] =
                  eachValue[textLabel].split("(desc:");
                const cleanDescription = description
                  ? description.replace(")", "").trim()
                  : null;

                return (
                  <SelectItem
                    value={eachValue[valueLabel]}
                    disabled={label.includes("(Pro)")}
                    description={cleanDescription}
                  >
                    {label.replace(" (Pro)", "").trim()}
                  </SelectItem>
                );
              })}
            </Select.Group>
          </Select.Viewport>
          <Select.ScrollDownButton className="SelectScrollButton">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

const SelectItem = React.forwardRef(
  ({ children, className, disabled, description, ...props }, forwardedRef) => {
    // Define a set of icons to mix and match for descriptions
    const descriptionIcons = [
      <BsRocketTakeoff fontSize={10} className="selectIcon" />,
      <FaSatellite fontSize={10} className="selectIcon" />,
      <FaSpaceShuttle fontSize={10} className="selectIcon" />,
      <FaMeteor fontSize={10} className="selectIcon" />,
    ];

    // Function to get a random but unique icon for each description
    const usedIcons = new Set();

    const getRandomUniqueIcon = () => {
      if (usedIcons.size === descriptionIcons.length) {
        usedIcons.clear(); // Reset if all icons have been used
      }

      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * descriptionIcons.length);
      } while (usedIcons.has(randomIndex));

      usedIcons.add(randomIndex);
      return descriptionIcons[randomIndex];
    };

    return (
      <Select.Item
        className={classnames("SelectItem", className, {
          SelectItemDisabled: disabled,
        })}
        {...props}
        ref={forwardedRef}
        disabled={disabled}
      >
        <div>
          <Select.ItemText>
            {children}
            {disabled && <span className="newBadge">PRO</span>}
          </Select.ItemText>
          {description && (
            <div
              className="SelectItemDescription"
              style={{
                opacity: disabled ? 0.5 : 1,
                color: disabled ? "gray !important" : "#573ef6",
              }}
            >
              {getRandomUniqueIcon()} {description}
            </div>
          )}
        </div>
        {!disabled && (
          <Select.ItemIndicator className="SelectItemIndicator">
            <CheckIcon />
          </Select.ItemIndicator>
        )}
      </Select.Item>
    );
  }
);

export default SelectDropdown;
