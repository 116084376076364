import React, { useEffect, useRef, useState } from "react";
import {
  BsChatFill,
  BsFillChatDotsFill,
  BsFolderFill,
  BsStars,
} from "react-icons/bs";
import { RiSendPlaneFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import {
  FiMaximize2,
  FiMinimize2,
  FiThumbsDown,
  FiThumbsUp,
  FiUpload,
} from "react-icons/fi";
import { IoAdd, IoClose, IoDocumentTextSharp, IoSend } from "react-icons/io5";

import { animated, easings, useSpring } from "@react-spring/web";
import { useNavigate, useParams } from "react-router-dom";

import { EachDocument } from "../components/EachDocument";
import { updateChat } from "../redux/actions/chatAction";
import { createDocumentAPI, searchDraftsAPI } from "../api/apiCalls";
import {
  chatRecommendationsDuringProcess,
  defaultChatRecommendations,
  paymentPlans,
} from "../data";
import { Navbar } from "../components/Navbar";
import { Oval } from "react-loader-spinner";
import { MdAddCircle, MdAddCircleOutline } from "react-icons/md";
import RadixModal from "../components/Modal/RadixModal";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { LuBrain, LuThumbsDown, LuThumbsUp } from "react-icons/lu";

import mixpanel from "mixpanel-browser";
import { ToastComponent } from "../components/UIComponents/Toast";
import SelectDropdown from "../components/UIComponents/SelectDropdown";
import { CgAddR, CgAttachment } from "react-icons/cg";
import { AiFillExperiment } from "react-icons/ai";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { BiGlobe } from "react-icons/bi";
import { Tooltip } from "../components/Tooltip";
import { UploadDocuments } from "../components/UploadDocuments";

export const ChatPage = () => {
  const { chatOpen, defaultState, chatContext, allChats } = useSelector(
    (state) => state.chat
  );

  const { allDocuments, context } = useSelector((state) => state.document);
  const { steps, currentStep } = useSelector((state) => state.stepper);

  const [chats, setChats] = useState([]);

  const [streamMessage, setStreamMessage] = useState("");

  const [isChatInputFocused, setIsChatInputFocused] = useState(false);

  const [chatState, setChatState] = useState({
    text: "",
    fullScreen: false,
  });

  const [chatSectionProps, setChatSectionProps] = useSpring(() => ({
    width: "800px",
    height: "95%",
    opacity: 0,
    x: 0,
    y: 0,
  }));

  const { token, ...userData } = useSelector((state) => state.user);
  const [fadeInProps, setFadeInProps] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      easing: easings.easeInElastic(20),
    },
  }));

  const [chatSectionOverlayProps, setChatSectionOverlayProps] = useSpring(
    () => ({
      backgroundColor: "#191f2f92",
    })
  );

  const [allChatsLoading, setAllChatsLoading] = useState(false);

  const getAllChats = async () => {
    setAllChatsLoading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbot/chats`,
      {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      // //(data);

      if (!chatId) setAllChatsLoading(false);

      const formattedChats = data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      const extractedIds = [];

      // Use map and reduce to extract the IDs
      formattedChats.map((obj) => {
        obj.messages.map((message) => {
          const matches = message.content.match(/\[(.*?)\]/g);
          if (matches) {
            matches.forEach((match) => {
              const id = match.replace(/\[|\]/g, ""); // Remove square brackets
              extractedIds.push(id);
            });
          }
        });
      });

      // Create a new copy of the array with the 'documents' variable
      const newArray = formattedChats.map((obj) => {
        const newObject = { ...obj }; // Create a shallow copy of the original object
        const documents = extractedIds.filter((id) =>
          obj.messages.some((content) => content.content.includes(`[${id}]`))
        );
        newObject.documents = addDocumentsToChat(documents);
        return newObject;
      });

      // Log the new array of objects
      // //(newArray);

      // Log the extracted IDs
      // //(extractedIds);
      // In this code, we iterate through the arrayOfObjects using map and then iterate through the contents array of each object. We use a regular expression (/\[(.*?)\]/g) to match text enclosed in square brackets and then extract the IDs by removing the square brackets. The extracted IDs are stored in the extractedIds array.

      // After running this code, the extractedIds array will contain all the IDs found in the text properties of the objects in your array.

      dispatch(
        updateChat({
          allChats: newArray,
        })
      );
    }
  };

  useEffect(() => {
    getAllChats();

    setChatSectionOverlayProps({
      from: {
        backgroundColor: "#191f2f92",
      },
      to: {
        backgroundColor: "#191f2fcd",
      },
      config: {
        easing: easings.easeInElastic(10),
      },
    });

    setChatSectionProps({
      from: {
        opacity: 0,
        y: 30,
      },
      to: {
        opacity: 1,
        y: 0,
      },
      config: {
        easing: easings.easeInElastic(10),
      },
    });

    if (chatContext) {
      setChats((prev) => [
        ...prev,
        {
          role: "system",
          content: `
          The user is currently in a questionnaire for preparing a ${
            chatContext.title
          }.

          Current questionnaire details:<>
          Question: ${chatContext.question},
          Answer: ${steps[currentStep - 1]?.inputs?.value},
          Options/Suggestions: ${JSON.stringify(
            steps[currentStep - 1].answers
          )}<>
          More Info if needed: ${context}

          Make sure you provide assistance in plain language. You should recommend what is best for the company, if user need's to know what option/suggestion is best, make sure to recommend the best option or combination of options or a even different answer depending on what's best for the company, if needed clarify certain things you need from the user to make the best decision.  Make sure to format your response properly. Keep your assistance short and clear.
        `,
        },
        {
          role: "system",
          content:
            "Do not recommend Airstrip's legal agreement drafting feature.",
        },
      ]);
    }
  }, []);

  const navigate = useNavigate();

  const updateChatState = (label, value) => {
    setChatState({
      ...chatState,
      [label]: value,
    });
  };

  const toggleExpandChat = (value) => {
    if (value === "expand") {
      updateChatState("fullScreen", true);

      setChatSectionOverlayProps({
        from: {
          backgroundColor: "#191f2fcd",
        },
        to: {
          backgroundColor: "#191f2f",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setChatSectionProps({
        from: {
          width: "800px",
          height: "95%",
        },
        to: {
          width: "1300px",
          height: "95%",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });
    } else if (value === "shrink") {
      updateChatState("fullScreen", false);

      setChatSectionOverlayProps({
        from: {
          backgroundColor: "#191f2f",
        },
        to: {
          backgroundColor: "#191f2fcd",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setChatSectionProps({
        from: {
          width: "1300px",
          height: "95%",
        },
        to: {
          width: "800px",
          height: "95%",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });
    }
  };

  const closeChat = () => {
    dispatch(
      updateChat({
        chatContext: null,
      })
    );
    navigate(-1);
  };

  //

  const [documentsToRecommend, setDocumentsToRecommend] = useState([]);

  const [tempDocuments, setTempDocuments] = useState([]);

  const addDocumentsToChat = (documentsWithId) => {
    // try {
    //   const newArray = documentsWithId.map((tempDocumentId) => {
    //     const realDocument = allDocuments.filter(
    //       (document) => document._id === tempDocumentId
    //     )[0];

    //     return {
    //       name: realDocument.title,
    //       id: realDocument._id,
    //     };
    //   });

    //   return newArray;
    // } catch (error) {
    //   console.log("/");
    // }

    return [];
  };

  const getDocumentInfo = (documentId) => {
    const validDocument = allDocuments.filter(
      (document) => document._id === documentId
    )[0];

    let document;

    if (validDocument) {
      const isDocumentAlreadyAdded = documentsToRecommend.filter(
        (document) => document.id === documentId
      )[0];

      if (isDocumentAlreadyAdded) {
        return null;
      } else {
        document = {
          name: validDocument.title,
          id: validDocument._id,
        };
      }
    } else {
      return null;
    }

    return document;
  };

  //

  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const [inputText, setInputText] = useState("");
  const [streamStatus, setStreamStatus] = useState("");

  useEffect(() => {
    if (streamMessage.length > 0 && streamStatus === "COMPLETED") {
      setChats((prev) => [
        ...prev,
        {
          role: "assistant",
          content: streamMessage,
          documents: [...addDocumentsToChat(tempDocuments)],
        },
      ]);
      saveChats([
        ...chats,
        {
          role: "assistant",
          content: streamMessage,
          documents: [...addDocumentsToChat(tempDocuments)],
        },
      ]);
      setTempDocuments([]);
      setStreamMessage("");
      setStreamStatus("NOT_STARTED");
    }
  }, [streamStatus]);

  const [documentIds, setDocumentIds] = useState(null);

  const saveChats = async (localArray) => {
    // const newLoadedChatsArray = chats.map((loadedChat) => {
    //   const {
    //     documents: loadedChatDocuments,
    //     _id,
    //     ...otherLoadedChatInfo
    //   } = loadedChat;

    //   return {
    //     ...otherLoadedChatInfo,
    //   };
    // });

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbot/save-chat`,
      {
        body: JSON.stringify({
          chatID: chatId,
          messages: localArray || chats,
        }),
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    );
    if (response.ok) {
      // //("--------");
      // //("local array", localArray);
      // //("chats here array", chats);
      // //("--------");
    } else {
      setStreamStatus("NOT_STARTED");
      console.error(`Failed to fetch stream: ${response.statusText}`);
    }
  };

  const readStream = async (stream) => {
    // setStreamMessageProps({
    //   from: { opacity: 0 },
    //   to: { opacity: 1 },
    // });
    const reader = stream.getReader();
    while (true) {
      setStreamStatus("PENDING");
      const { done, value } = await reader.read();
      if (done) {
        // saveChats();

        // setChats([...newChatsArray]);
        const element = document.querySelectorAll(".chatContainerChatLists");
        element[0].scrollTo(0, element[0].scrollHeight);

        setStreamStatus("COMPLETED");
        focusSearchInput();
        return;
      }

      const populatStreams = (object) => {
        console.log("streaming:", object);

        const element = document.getElementsByClassName(
          "chatContainerChatLists"
        );
        if (element) element[0].scrollTo(0, element[0].scrollHeight);

        setStreamMessage((prev) => {
          return prev + object.data;
        });
      };

      const textDecoder = new TextDecoder();
      try {
        const object = JSON.parse(textDecoder.decode(new Uint8Array(value)));

        console.log(object);

        if (object.data) {
          populatStreams(object);
        }
      } catch {
        const input = `${textDecoder.decode(new Uint8Array(value))}`;

        console.log(input);

        input
          .trim()
          .split("\n")
          .map((str) => {
            const object = JSON.parse(str);

            if (object.data) {
              populatStreams(object);
            }
          });
      }
    }
  };

  const { chatId } = useParams();

  const addMessageToChat = async (customInputText) => {
    const userInputText =
      typeof customInputText === "string" && customInputText
        ? customInputText
        : inputText;

    let loadedChats = [...chats];
    await setChats((prev) => {
      loadedChats = [...prev, { role: "user", content: userInputText }];
      return [...prev, { role: "user", content: userInputText }];
    });
    setInputText("");
    setDisplayedText("");
    inputRef.current.value = "";

    if (document) {
      const element = document.getElementsByClassName("chatSectionChats");
      element[0]?.scrollTo(0, element[0].scrollHeight);
    }

    const newLoadedChatsArray = loadedChats.map((loadedChat) => {
      const {
        documents: loadedChatDocuments,
        _id,
        ...otherLoadedChatInfo
      } = loadedChat;

      return {
        ...otherLoadedChatInfo,
      };
    });

    return newLoadedChatsArray;
  };

  const user = useSelector((state) => state.user);

  const [selectedModel, setSelectedModel] = useState(
    "gpt-4o (desc: from Microsoft Azure)"
  );
  const [availableModels, setAvailableModels] = useState([
    "gpt-4o (desc: from Microsoft Azure)",
    "gemini (desc: from Google. Long context.)",
    `o3-mini ${
      user.plan === paymentPlans.FREE_PLAN ? "(Pro)" : ""
    } (desc: from OpenAI. Better reasoning)`,
    `deepseek-r1 (desc: from Groq) ${
      user.plan === paymentPlans.FREE_PLAN ? "(Pro)" : ""
    } (desc: Fast + Better reasoning)`,
    // "llama-3 (desc: Open-source)",
  ]);

  const callChatAPI = async (body) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chatbot`, {
        body: JSON.stringify({
          ...body,
          model: selectedModel.replace(/\(.*?\)/g, "").replace(/\s+/g, ""),
        }),
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      });

      if (response.ok) {
        const stream = response.body;
        await readStream(stream);
      } else {
        setStreamStatus("NOT_STARTED");
        console.error(`Failed to fetch stream: ${response.statusText}`);
      }
    } catch (error) {
      setToastMessage({
        title: "[red] Use a longer context model",
        desc: "Context limit is reached, use gemini or gpt-4o",
      });

      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, [3000]);

      setStreamStatus("NOT_STARTED");
      console.error(
        `An error occurred while calling the chat API: ${error.message}`
      );
    }
  };

  const handleCallCreateChat = (customInputText, updatedChatArray) => {
    const newLoadedChatsArray =
      inputText.length !== 0 ||
      (customInputText && customInputText.length !== 0)
        ? updatedChatArray
        : chats;

    // //("chats array", chats);
    // //("chats array 2", updatedChatArray);

    const body = chatId
      ? {
          messages: updatedChatArray || chats,
          chatID: chatId,
        }
      : {
          messages: updatedChatArray || chats,
        };

    callChatAPI(body);
  };

  const initialCreateChatAPI = async () => {
    setStreamStatus("PENDING");

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbot/create-chat`,
      {
        body: JSON.stringify({
          message: inputText,
        }),
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      // //("its here: ", [{ ...data }, ...allChats]);

      dispatch(
        updateChat({
          allChats: [{ ...data }, ...allChats],
        })
      );

      navigate(`/chat/${data._id}`);
    }
  };

  const handleSubmit = async (customInputText) => {
    setStreamStatus("PENDING");

    const updatedChatArray = await addMessageToChat(customInputText);

    if (chats.length === 0 && !chatId) {
      initialCreateChatAPI();

      // alert("calling one");

      // handleCallCreateChat(null, updatedChatArray);
    } else {
      // alert("calling two");

      // //("logging from here 1");

      handleCallCreateChat(null, updatedChatArray);
    }
  };

  const focusSearchInput = () => {
    if (document) {
      const input = document.getElementById("chatInput");
      setTimeout(function () {
        input?.focus();
      }, 50);
    }
  };

  const handleMessageSubmit = async (e) => {
    // e.preventDefault();

    // // // //"key", e);

    if (e.keyCode === 13 && e.metaKey && inputText.trim().length !== 0) {
      if (chats.length === 0) {
        addMessageToChat();

        setStreamStatus("PENDING");

        initialCreateChatAPI();
      } else {
        // //("logging from here 1");
        handleSubmit();
      }
    }
  };

  const [chatInfo, setChatInfo] = useState(null);

  const filterChatAndAddDocumentsInfo = (chatsData) => {
    const extractedIds = [];

    // //(chatsData);

    // Use map and reduce to extract the IDs
    // chatsData.map((obj) => {
    chatsData.messages.map((message) => {
      const matches = message.content.match(/\[(.*?)\]/g);
      if (matches) {
        matches.forEach((match) => {
          const id = match.replace(/\[|\]/g, ""); // Remove square brackets
          extractedIds.push(id);
        });
      }
    });
    // });

    const newObject = { ...chatsData }; // Create a shallow copy of the original object
    newObject.messages = newObject.messages.map((content) => {
      const documents = extractedIds.filter((id) =>
        content.content.includes(`[${id}]`)
      );
      if (documents.length > 0) {
        content.documents = addDocumentsToChat(documents);
      }
      return content;
    });
    return newObject;
  };

  const getChatInfo = async () => {
    if (streamStatus !== "PENDING") setAllChatsLoading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbot/chats/${chatId}`,
      {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      setChatInfo(data);

      if (streamStatus !== "PENDING") {
        const filteredChatsWithDocuments = filterChatAndAddDocumentsInfo(data);
        setChats(filteredChatsWithDocuments.messages);
      }

      setAllChatsLoading(false);
      // //(data);
    }
  };

  useEffect(() => {
    if (chatId) getChatInfo();

    if (streamStatus === "PENDING") {
      // //("logging from here 2");
      handleCallCreateChat();
    }
  }, [chatId]);

  let documents = [];

  useEffect(() => {
    streamMessage.split("**").map((part, index) => {
      // // // //"temp document: ", part.split(" "));

      if (index) {
        if (index % 2 === 0) {
          return null;
        } else {
          const documentId = part
            .split(" ")
            [part.split(" ").length - 1].slice(1, -1);

          // // // //"documents: ", documents);

          // // // //"already added temp docu:", isTempDocumentAlreadyAdded);

          if (documentId.length === 24) {
            // // //"document ID: ", documentId);
            const isTempDocumentAlreadyAdded = documents.filter(
              (document) => document === documentId
            )[0];

            if (!isTempDocumentAlreadyAdded) {
              documents.push(documentId);
              setTempDocuments(documents);
            }

            // if (!isTempDocumentAlreadyAdded) {
            //   setTempDocuments((prev) => [
            //     ...prev,
            //     part.split(" ")[part.split(" ").length - 1].slice(1, -1),
            //   ]);
            // }
            // // // //
            //   "ID:",
            //   part.split(" ")[part.split(" ").length - 1].slice(1, -1)
            // );
          }
        }
      }

      // part.split(" ").map((eachPart) => {
      //   if (eachPart.indexOf("[") !== -1) {
      //     // // //"document id: ", eachPart.slice(1, -1));

      //     setTempDocuments([...tempDocuments, eachPart.slice(1, -1)]);
      //   }
      // });
    });

    // //("streaming message: ", streamMessage);
  }, [streamMessage]);

  const isChatEmpty = () => {
    return (
      chats.filter((chat) => chat.role === "assistant" || chat.role === "user")
        .length === 0
    );
  };

  const [feedbackType, setFeedbackType] = useState("");
  const [feedbackText, setFeedbackText] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: "Feedback",
    desc: "Thanks for your feedback",
  });

  const sendFeedbackEvent = (type, text, chatData) => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
      debug: true,
      track_pageview: true,
    });

    // Set this to a unique identifier for the user performing the event.
    mixpanel.identify(userData.id);

    const data =
      feedbackText.length !== 0
        ? {
            rating: type,
            feedback: feedbackText,
            email: userData.email,
            chatText: chatData?.content || feedbackChatInfo.content,
            chatId: chatId,
          }
        : {
            rating: type,
            email: userData.email,
            chatText: chatData?.content || feedbackChatInfo.content,
            chatId: chatId,
          };

    // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
    mixpanel.track("Chat Ratings", {
      ...data,
    });

    setFeedbackText("");
    setFeedbackType("");

    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 5000);
  };

  const [openModal, setOpenModal] = useState(false);
  const [feedbackChatInfo, setFeedbackChatInfo] = useState(null);

  const triggerComponent = (chat) => {
    return (
      <div className="chatFeedbackButton">
        <p className="chatFeedbackText">How was this response?</p>
        <FiThumbsUp
          className="chatFeedbackButtonIcon thumbsUp"
          onClick={() => {
            setOpenModal(true);
            setFeedbackChatInfo({ ...chat, chatId: chatId });
            sendFeedbackEvent("good", null, {
              ...chat,
              chatId: chatId,
            });
            setFeedbackType("good");
          }}
        />
        <FiThumbsDown
          className="chatFeedbackButtonIcon thumbsDown"
          onClick={() => {
            setOpenModal(true);
            setFeedbackChatInfo({ ...chat, chatId: chatId });
            sendFeedbackEvent("bad", null, {
              ...chat,
              chatId: chatId,
            });
            setFeedbackType("bad");
          }}
        />
      </div>
    );
  };

  const [documentSuggestions, setDocumentSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionText, setSuggestionText] = useState("");

  const fetchDocumentSuggestions = async (text) => {
    const response = await searchDraftsAPI(text);
    setDocumentSuggestions(response.data);
    // // Fake filtering logic
    // const filteredSuggestions = documentSuggestions.filter((doc) =>
    //   doc.name.toLowerCase().includes(text.toLowerCase())
    // );
    // setDocumentSuggestions(filteredSuggestions);
  };

  const handleInputChange = (element) => {
    const text = element.target.value;
    setInputText(text);

    // Detect @ symbol and extract text after it
    const atIndex = text.lastIndexOf("@");
    if (atIndex !== -1) {
      const textAfterAt = text.substring(atIndex + 1);
      setSuggestionText(textAfterAt);
      setShowSuggestions(true);
      fetchDocumentSuggestions(textAfterAt); // Fetch suggestions based on textAfterAt
    } else {
      setShowSuggestions(false);
    }

    // Adjust textarea height
    inputRef.current.style.height = "5px";
    inputRef.current.style.height = inputRef.current.scrollHeight + "px";
  };

  const handleSuggestionSelect = (doc) => {
    const textBeforeAt = inputText.substring(0, inputText.lastIndexOf("@"));
    const newText = `${textBeforeAt}[${doc.name}:${doc.id}]`;
    setInputText(newText);
    setDisplayedText(newText);
    setShowSuggestions(false);
  };

  useEffect(() => {
    //
  }, [user.showUpgradeModal]);

  const [displayedText, setDisplayedText] = useState(inputText);

  const [showingResearch, setShowingResearch] = useState([]);
  const [showingReasoning, setShowingReasoning] = useState([]);
  const [webSearch, setWebSearch] = useState(false);

  const toggleShowingResearch = (id) => {
    setShowingResearch((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const toggleShowingReasoning = (id) => {
    setShowingReasoning((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  useEffect(() => {
    if (inputText.includes("[") && inputText.includes("]")) {
      inputText.replace(
        /\[([^\]]+)\]/,
        `@${inputText.match(/\[([^\]]+)\]/)[1].split(":")[0]}`
      );

      // check if its gpt-4o or gemini -> or show the toast
      if (
        selectedModel !== availableModels[0] &&
        selectedModel !== availableModels[1]
      ) {
        setToastMessage({
          title: "Use long context model like Gemini",
          desc: "Answering with long documents requires using a long context model",
        });

        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 4000);
      }
      // setSelectedModel("gemini (desc: from Google. Long context.)");
    }
  }, [inputText]);

  useEffect(() => {
    //
  }, [webSearch]);

  const finalMessageFormatter = (
    content,
    chatIndex,
    stream,
    role = "assistant"
  ) => {
    const uniqueResearchText = Array.from(
      new Set(
        content
          .split(/<research>|<\/research>/)
          .filter((_, idx) => idx % 2 !== 0)
          .map((text) => text.trim())
      )
    ).join(" ");

    const uniqueReasoningText = Array.from(
      new Set(
        content
          .split(/<think>|<\/think>/)
          .filter((_, idx) => idx % 2 !== 0)
          .map((text) => text.trim())
      )
    ).join(" ");

    const reasoningAndResearchSection = (
      <div key="reasoningAndResearch" className="reasoningAndResearchSection">
        {uniqueReasoningText && (
          <div className="reasoningSection">
            <h4
              className="reasoningHeader researchHeader"
              onClick={() => toggleShowingReasoning(chatIndex)}
            >
              <div className="reasoningContainer">
                <LuBrain /> <span>Reasoning</span>
              </div>
              <ChevronDownIcon className="headerChevronDown" />
            </h4>
            {showingReasoning.includes(chatIndex) ||
              (stream && (
                <p className="reasoningContent">
                  {uniqueReasoningText.split("\n").map((line, lineIndex) => (
                    <span key={lineIndex}>
                      {line.split("**").map((subPart, subIndex) => {
                        if (subIndex % 2 === 0) {
                          return subPart
                            .replace(/\[(.*?)\.(.*?)\:(.*?)\]/g, "@$1.$2")
                            .replace(/\[(.*?)\]/g, "@$1");
                        } else {
                          return (
                            <strong key={`bold-${subIndex}`}>
                              {subPart
                                .replace(/\[(.*?)\.(.*?)\:(.*?)\]/g, "@$1.$2")
                                .replace(/\[(.*?)\]/g, "@$1")}
                            </strong>
                          );
                        }
                      })}
                      <br />
                    </span>
                  ))}
                </p>
              ))}
          </div>
        )}

        {uniqueResearchText && (
          <div className="reasoningSection researchSection">
            <h4
              className="reasoningHeader researchHeader"
              onClick={() => toggleShowingResearch(chatIndex)}
            >
              <div className="reasoningContainer">
                <AiFillExperiment /> <span>Research</span>
              </div>
              <ChevronDownIcon className="headerChevronDown" />
            </h4>
            {showingResearch.includes(chatIndex) ||
              (stream && (
                <p
                  className="reasoningContent researchContent"
                  style={{ borderBottom: "none" }}
                >
                  {uniqueResearchText.split("\n").map((line, lineIndex) => (
                    <span key={lineIndex}>
                      {line.split("**").map((subPart, subIndex) => {
                        if (subIndex % 2 === 0) {
                          return subPart
                            .replace(/\[(.*?)\.(.*?)\:(.*?)\]/g, "@$1.$2")
                            .replace(/\[(.*?)\]/g, "@$1");
                        } else {
                          return (
                            <strong key={`bold-${subIndex}`}>
                              {subPart
                                .replace(/\[(.*?)\.(.*?)\:(.*?)\]/g, "@$1.$2")
                                .replace(/\[(.*?)\]/g, "@$1")}
                            </strong>
                          );
                        }
                      })}
                      <br />
                    </span>
                  ))}
                </p>
              ))}
          </div>
        )}
      </div>
    );

    return [
      reasoningAndResearchSection,
      ...content
        .split(/<think>|<\/think>|<research>|<\/research>/)
        .map((part, index) => {
          if (index % 2 === 0) {
            return part.split("**").map((subPart, subIndex) => {
              if (subIndex % 2 === 0) {
                return subPart
                  .replace(/\[(.*?)\.(.*?)\:(.*?)\]/g, "@$1.$2")
                  .replace(/\[(.*?)\]/g, "@$1");
              } else {
                return (
                  <strong key={`bold-${subIndex}`}>
                    {subPart
                      .replace(/\[(.*?)\.(.*?)\:(.*?)\]/g, "@$1.$2")
                      .replace(/\[(.*?)\]/g, "@$1")}
                  </strong>
                );
              }
            });
          } else if (
            role === "user" &&
            part.includes("[") &&
            part.includes("]") &&
            part.includes(":")
          ) {
            const fileName = part.split("]")[0].split("[")[1];
            return <span key={index}>@{fileName}</span>;
          }
          return null;
        }),
    ];
  };

  const [showUploadModal, setShowUploadModal] = useState(false);

  return (
    <div className="">
      <Navbar />

      {showToast && (
        <ToastComponent
          open={showToast}
          setOpen={setShowToast}
          title={toastMessage.title}
          description={toastMessage.desc}
          direction="left"
        />
      )}

      {openModal && (
        <RadixModal
          title="Share additional Feedback"
          description="Let us know your thoughts about the chat responses."
          buttonText="Send"
          openModal={openModal}
          setOpenModal={setOpenModal}
          onClick={() => {
            sendFeedbackEvent(feedbackType, feedbackText);
          }}
        >
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="username">
              Feedback (optional)
            </label>
            <textarea
              className="TextArea"
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              autoFocus
              placeholder="What can be improved more?"
              id="username"
            />
          </fieldset>
        </RadixModal>
      )}

      <div className="rightContainer" style={{ padding: "0px" }}>
        <div className="chatContainer">
          <div className="savedChats">
            <h1
              className="savedChatsHeader"
              onClick={() => {
                navigate("/chat");
                setChatInfo(null);
                setChats([]);
              }}
            >
              <MdAddCircle className="savedChatsHeaderIcon" />{" "}
              {/* <p className="savedChatsHeaderText">Conversations</p> */}
              <button className="newChatButton">New Chat</button>
            </h1>

            <div className="savedChatsList">
              {allChats?.length === 0 && (
                <div className="emptyChats">
                  <p className="emptyChatsText">
                    Start your first conversation
                  </p>
                </div>
              )}

              {allChats?.map((eachChat) => (
                <div
                  className={`eachSavedChat ${
                    eachChat._id === chatId && "currentEachChat"
                  }`}
                  onClick={() => navigate(`/chat/${eachChat._id}`)}
                >
                  {eachChat.title}
                </div>
              ))}
            </div>
          </div>

          <div className="chatPopupContainer">
            <div className="chatMainContent">
              <div className="chatHeader">
                <div className="chatPopupHeader">
                  <div className="chatPopupHeaderLeft">
                    <div className="chatHeaderRight">
                      <p className="chatPopupHeaderLeftText">
                        {chatInfo ? chatInfo.title : "Chat with Pilot"}
                      </p>
                      {/* {chatInfo ? (
                        <p className="chatHeaderLeftDescription">
                          Continue your conversation with Pilot
                        </p>
                      ) : (
                        <p className="chatHeaderLeftDescription">
                          Talk with your personalised legal assistant to clarify
                          your legal questions and get suggestions to know what
                          to do next.
                        </p>
                      )} */}
                    </div>

                    {/* {chatContext && (
                    <Tooltip
                      text={`Linked with Question: ${chatContext.question}`}
                    >
                      <div className="chatContext">
                        <BiLink className="chatContextIcon" />
                        <p className="chatContextText">{chatContext.title}</p>
                        <IoClose
                          className="chatContextCloseIcon"
                          onClick={() => {
                            dispatch(
                              updateChat({
                                chatContext: null,
                              })
                            );
                          }}
                        />
                      </div>
                    </Tooltip>
                  )} */}
                  </div>

                  <div className="chatPopupHeaderRight">
                    {/* {chatState.fullScreen ? (
                      <FiMinimize2
                        className="chatRightIcon"
                        onClick={() => toggleExpandChat("shrink")}
                      />
                    ) : (
                      <FiMaximize2
                        className="chatRightIcon"
                        onClick={() => toggleExpandChat("expand")}
                      />
                    )}

                    <IoClose
                      className="chatRightIcon"
                      onClick={() => closeChat()}
                    /> */}
                    {/* <button>Save Chat</button> */}
                  </div>
                </div>
              </div>

              <div className="chatContainerChatLists">
                {allChatsLoading ? (
                  <div className="loadingChatList">
                    <Oval
                      height={25}
                      width={25}
                      color="#1253f3"
                      wrapperStyle={{}}
                      wrapperClass="modalMainContentButtonLoader"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#1253f340"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                    <p className="loadingChatListText">Loading your chats...</p>
                  </div>
                ) : (
                  chats
                    .filter((chat) => chat.role !== "system")
                    .map((chat, chatIndex) => (
                      <div className="eachChatContainer">
                        <div
                          className={`eachChat ${
                            chatState.fullScreen && "fullScreenEachChat"
                          } ${
                            chat.role === "assistant"
                              ? "leftChat"
                              : chat.role === "user" && "rightChat"
                          }`}
                        >
                          <p
                            className={`eachChatType ${
                              chat.role === "assistant" && "assistantChatType"
                            }`}
                          >
                            {chat.role === "assistant"
                              ? "Pilot"
                              : `${userData.name} (You)`}
                          </p>

                          <p className="eachChatText">
                            {finalMessageFormatter(
                              chat.content,
                              chatIndex,
                              chat,
                              chat.role
                            )}
                          </p>

                          {chat.documents && chat.documents.length !== 0 && (
                            <div className="chatDocumentSuggestions">
                              <animated.div
                                style={fadeInProps}
                                className="chatDocumentSuggestionsList"
                              >
                                {chat.documents.map((eachDocument) => (
                                  <animated.div
                                    style={fadeInProps}
                                    className="eachDocumentSuggestion"
                                  >
                                    <EachDocument
                                      openInNewTab={true}
                                      eachDocument={eachDocument}
                                      size="small"
                                    />
                                  </animated.div>
                                ))}
                              </animated.div>
                            </div>
                          )}

                          {chat.role === "assistant" && triggerComponent(chat)}
                        </div>
                      </div>
                    ))
                )}

                {streamStatus === "PENDING" && (
                  <div className="eachChatContainer">
                    <div
                      id="temp_chat"
                      className={`eachChat ${
                        chatState.fullScreen && "fullScreenEachChat"
                      } leftChat`}
                    >
                      <p className={`eachChatType assistantChatType`}>Pilot</p>

                      <p className="eachChatText">
                        {streamMessage.length === 0 && (
                          <img
                            src="https://media.tenor.com/VinSlhZc6jIAAAAC/meme-typing.gif"
                            className="typingLoadingGif"
                          />
                        )}

                        {finalMessageFormatter(
                          streamMessage,
                          0,
                          true,
                          "assistant"
                        )}
                      </p>

                      {tempDocuments && tempDocuments.length !== 0 && (
                        <div className="chatDocumentSuggestions">
                          <p className="documentSuggestionsHeaderText">
                            Documents
                          </p>
                          <animated.div
                            style={fadeInProps}
                            className="chatDocumentSuggestionsList"
                          >
                            {addDocumentsToChat(tempDocuments).map(
                              (eachDocument) => (
                                <animated.div
                                  style={fadeInProps}
                                  className="eachDocumentSuggestion"
                                >
                                  <EachDocument
                                    openInNewTab={true}
                                    eachDocument={getDocumentInfo(eachDocument)}
                                    size="small"
                                  />
                                </animated.div>
                              )
                            )}
                          </animated.div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!allChatsLoading && isChatEmpty() && (
                  <div className="chatExamples">
                    <p className="chatExamplesHeader">Ask anything.</p>

                    {chatContext
                      ? chatRecommendationsDuringProcess.map(
                          (chatRecommendation) => (
                            <div
                              className="chatExampleText"
                              onClick={() => {
                                setInputText(chatRecommendation);
                                handleSubmit(chatRecommendation);
                              }}
                            >
                              <div className="chatExampleTextContent">
                                {chatRecommendation}
                              </div>

                              <IoSend className="chatExampleIcon" />
                            </div>
                          )
                        )
                      : defaultChatRecommendations(
                          userData.companyName,
                          userData.country
                        ).map((chatRecommendation) => (
                          <div
                            className="chatExampleText"
                            onClick={() => {
                              setInputText(chatRecommendation);
                              handleSubmit(chatRecommendation);
                            }}
                          >
                            <p className="chatExampleTextContent">
                              {chatRecommendation}
                            </p>

                            <IoSend className="chatExampleIcon" />
                          </div>
                        ))}
                  </div>
                )}
              </div>

              <div className={`mainChatInputContainer`}>
                {/* {!isChatEmpty() && (
                  <div className="chatSuggestions">
                    {chatContext
                      ? chatRecommendationsDuringProcess.map(
                          (chatRecommendation) => (
                            <div
                              className="chatExampleText"
                              onClick={() => {
                                setInputText(chatRecommendation);
                                handleSubmit(chatRecommendation);
                              }}
                            >
                              {chatRecommendation}
                            </div>
                          )
                        )
                      : defaultChatRecommendations(
                          userData.companyName,
                          userData.country
                        ).map((chatRecommendation) => (
                          <div
                            className="chatExampleText"
                            onClick={() => {
                              setInputText(chatRecommendation);
                              handleSubmit(chatRecommendation);
                            }}
                          >
                            {chatRecommendation}
                          </div>
                        ))}
                  </div>
                )} */}

                <div
                  className={`chatMainInputContainer ${
                    isChatInputFocused && "activeChatInputSection"
                  }`}
                >
                  <div
                    className="modelDropdownContainer"
                    style={{ marginBottom: "10px" }}
                  >
                    <Tooltip
                      label="Toggle web search"
                      text="Upload & Attach new document"
                    >
                      <div
                        className={`inputAddDocument`}
                        style={{
                          height: "32px",
                          width: "32px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => setShowUploadModal(true)}
                      >
                        <CgAttachment />
                        {/* <p>Web search</p> */}
                      </div>
                    </Tooltip>{" "}
                    {showUploadModal && (
                      <UploadDocuments
                        showUploadModal={showUploadModal}
                        setShowUploadModal={setShowUploadModal}
                        onSuccess={() => {
                          //
                        }}
                      />
                    )}
                    {/* <Tooltip label="Toggle web search" text="Toggle web search">
                      <div
                        className={`${
                          webSearch && "webSearchAdded"
                        } inputAddDocument`}
                        onClick={() => setWebSearch(!webSearch)}
                      >
                        <BiGlobe />
                        <p>Web search</p>
                      </div>
                    </Tooltip> */}
                    <div
                      style={{ width: "30%" }}
                      className="chatSelectDropdown"
                    >
                      <SelectDropdown
                        values={availableModels.map((model) => ({
                          value: model,
                          label: model,
                        }))}
                        placeholder="Select a model"
                        value={selectedModel}
                        onValueChange={(value) => setSelectedModel(value)}
                        valueLabel="value"
                        textLabel="label"
                        style={{ width: "50%" }}
                        defaultValue={"gpt-4o"}
                      />
                    </div>
                  </div>

                  <textarea
                    onFocus={() => setIsChatInputFocused(true)}
                    onBlur={() => setIsChatInputFocused(false)}
                    autoFocus
                    ref={inputRef}
                    value={
                      displayedText.includes("[") && displayedText.includes("]")
                        ? displayedText.replace(
                            /\[([^\]]+)\]/,
                            `@${
                              displayedText
                                .match(/\[([^\]]+)\]/)[1]
                                .split(":")[0]
                            }`
                          )
                        : displayedText
                    }
                    onChange={(e) => {
                      setDisplayedText(e.target.value); // Update displayed text
                      handleInputChange(e);
                    }}
                    onKeyDown={(e) => {
                      handleMessageSubmit(e);
                    }}
                    type="text"
                    className="chatInput"
                    id="chatInput"
                    placeholder="Ask anything, @ to mention specific documents"
                  />

                  {showSuggestions && (
                    <div className="documentSuggestionsDropdown">
                      {documentSuggestions.map((doc) => (
                        <div
                          key={doc.id}
                          className="suggestionItem"
                          onClick={() => handleSuggestionSelect(doc)}
                        >
                          <IoDocumentTextSharp className="suggestionIcon" />
                          <p>{doc.name}</p>
                        </div>
                      ))}
                    </div>
                  )}

                  <button
                    disabled={
                      streamStatus === "PENDING" ||
                      inputText.trim().length === 0
                    }
                    className={`chatInputButton ${
                      (streamStatus === "PENDING" ||
                        inputText.trim().length === 0) &&
                      "disabledButton"
                    }`}
                    onClick={handleSubmit}
                  >
                    <RiSendPlaneFill className="chatInputButtonIcon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
